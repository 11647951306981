<template>
  <div id="__app">
    <sprite />
    <notifications
      group="check-notifications"
      :ignore-duplicates="true"
      :class="'check-notifications'"
    />
    <notifications group="app-notifications" />
    <router-view :sections="sections" />
  </div>
</template>

<script>
import Sprite from '@/components/common/Sprite'

export default {
  name: 'App',
  components: {
    Sprite
  },
  data() {
    return {
      sections: [
        {
          class: 'bg-transparent',
          name: 'VIDEO',
          id: '#video',
          inMenu: false
        },
        {
          class: 'bg-pink',
          name: 'MAIN',
          id: '#main',
          inMenu: false
        },
        {
          class: 'bg-dark',
          name: 'RACES & RARITY',
          id: '#races',
          inMenu: false
        },
        {
          name: 'SEASON 2',
          class: 'bg-dark',
          id: '#season-two',
          link: '/season-2',
          inMenu: true
        },
        {
          class: 'bg-dark',
          name: 'ROADMAP',
          id: '#roadmap',
          link: '/roadmap',
          inMenu: true
        },
        {
          name: 'SPACENOMICS',
          class: 'bg-pink',
          id: '#spacenomics',
          link: '/spacenomics',
          inMenu: true
        },
        {
          name: 'STAR SHOP',
          class: 'bg-dark',
          id: '#starshop',
          link: '/starshop',
          inMenu: true
        },
        {
          class: 'bg-dark',
          name: 'STAR POINTS',
          id: '#starpoints',
          link: '/starpoints',
          inMenu: false
        },
        {
          name: 'S1 RARITY',
          class: 'bg-dark',
          id: '#ranking',
          link: '/ranking',
          inMenu: true
        },
        {
          name: 'VOTE',
          class: 'bg-dark',
          id: '#voting',
          link: '/voting',
          inMenu: false
        },
        {
          name: 'FAQ',
          class: 'bg-dark',
          id: '#faq',
          inMenu: true
        },
        {
          name: 'WHITELIST ME',
          class: 'bg-dark',
          id: '#whitelist',
          link: '/whitelist',
          inMenu: false
        },
        {
          class: 'bg-pink',
          name: 'STORY',
          id: '#story'
        },
        {
          class: 'bg-pink',
          name: 'TEAM',
          id: '#team'
        }
      ]
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@500&display=swap')

#__app
  font-family: Rajdhani, sans-serif
</style>
