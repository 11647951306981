/* eslint-disable prettier-vue/prettier */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/views/HomePage'
import ErrorPage from '@/views/ErrorPage'
import RoadmapPage from '@/views/RoadmapPage'
import Spacenomics from '@/views/SpacePage'
import Starpoints from '@/views/PointsPage'
import RankPage from '@/views/RankPage'
import VotingPage from '@/views/VotingPage'
import WhitelistPage from '@/views/WhitelistPage'
import SeasonTwoPage from '@/views/SeasonTwoPage'
import StarShopPage from '@/views/StarShopPage'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/roadmap',
      name: 'Roadmap',
      component: RoadmapPage,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/spacenomics',
      name: 'Spacenomics',
      component: Spacenomics,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/starpoints',
      name: 'Starpoints',
      component: Starpoints,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/ranking',
      name: 'Ranking',
      component: RankPage,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/voting',
      name: 'Voting',
      component: VotingPage,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/whitelist',
      name: 'Whitelist',
      component: WhitelistPage,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/season-2',
      name: 'Season Two',
      component: SeasonTwoPage,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/starshop',
      name: 'StarShopPage',
      component: StarShopPage,
      meta: {
        title: 'M101 Shelter | NFT Collection of 10,101 Survivors',
        description: 'The M101 Shelter is a NFT Collection of 10.101 unique generative side-profile characters that survived on Ethereum Blockchain.'
      }
    },
    {
      path: '/*',
      name: '404',
      component: ErrorPage,
      meta: {
        title: '404'
      }
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEach(to, from, next) {
  await router.app.$nextTick();

  if (window.fbq !== 'undefined') {
    window.fbq('track', 'PageView');
  }
}

router.afterEach(afterEach)

export default router;
