/* eslint-disable prettier-vue/prettier */
import Vue from 'vue'
import App from './App.vue'
import Notifications from 'vue-notification'
import Helpers from './lib/Helpers.js'
import router from '@/router'
import VueMeta from 'vue-meta'
import Bowser from 'bowser'
import VueTypedJs from 'vue-typed-js'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/material.css'
import DynamicMarquee from 'vue-dynamic-marquee'
import Cookies from 'js-cookie'

import axios from 'axios'
import Web3 from 'web3'

const requestMethods = {
  getAccountsList: 'eth_accounts',
  callWalletConnection: 'eth_requestAccounts',
  callSwitchWallet: 'wallet_requestPermissions',
}

const walletConnectionProcessStartedErrorCode = -32002
const walletConnectionHeaderErrorCode = -32000

Vue.component('dynamic-marquee', DynamicMarquee)
Vue.component('VueSlider', VueSlider)

Vue.use(VueTypedJs)
Vue.use(Helpers)
Vue.use(Notifications)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.config.productionTip = false

window.appVue = new Vue({
  data() {
    return {
      windowTop: 0,
      width: null,
      height: null,
      desctopMinBreakpoint: 1500,
      tabletBreakpoint: 1200,
      tabletSmallBreakpoint: 1024,
      mobileBreakpoint: 768,
      bowser: null,
      isMetaMaskInstalled: false,
      isWalletConnected: false,
      currentWallet: null,
      currentOwner: {},
      starPoints: 0,
      tokenDef: {},
      authToken: Cookies.get('token'),
      apiUrl: 'https://nftsheltersuperadminpanel.m101.space/api',
      // apiUrl: 'https://shelter/api',
      countDown: {
        startTimePresale: new Date('2022-03-13T16:00:00.000+00:00').getTime(),
        endTimePresale: new Date('2022-03-13T22:00:00.000+00:00').getTime(),
        startTimeSale: new Date('2022-03-13T22:00:00.000+00:00').getTime(),
        hours: null,
        minutes: null,
        seconds: null
      },
      presaleStart: false,
      presaleEnd: false,
      saleStart: false,
      soldOut: false
    }
  },
  computed: {
    isDesctopMin() {
      return this.width <= this.desctopMinBreakpoint
    },
    isTablet() {
      return this.width <= this.tabletBreakpoint
    },
    isSmallTablet() {
      return this.width <= this.tabletSmallBreakpoint
    },
    isMobile() {
      return this.width <= this.mobileBreakpoint
    },
    headers() {
      return {
        headers: {
          Authorization: `Bearer ${this.authToken}`
        }
      }
    },
  },
  async created() {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.bowser = Bowser.parse(window.navigator.userAgent)

    this.handleTimerCountDown()
    setInterval(() => {
      this.handleTimerCountDown()
    }, 1000)
  },
  mounted() {
    this.addListeners()

    const ethereum = window.ethereum

    this.isMetaMaskInstalled = ethereum.isMetaMask

    if (this.isMetaMaskInstalled) {
      ethereum.on('accountsChanged', accounts => {
        this.handleAccountsChange(accounts)
      })
      this.ethereumRequest(requestMethods.getAccountsList)
    }

    this.getToken()
  },
  methods: {
    handleTimerCountDown() {
      const currentTime = new Date().getTime()
      const distancePresaleStart = this.countDown.startTimePresale - currentTime
      const distancePresaleEnd = this.countDown.endTimePresale - currentTime
      const distanceSaleStart = this.countDown.startTimeSale - currentTime

      this.presaleStart = distancePresaleStart <= 0
      this.presaleEnd = distancePresaleEnd <= 0
      this.saleStart = distanceSaleStart <= 0

      this.countDown.hours = Math.floor(
        ((this.presaleStart ? distanceSaleStart : distancePresaleStart) % (1000 * 60 * 60 * 600)) /
        (1000 * 60 * 60)
      )
      this.countDown.minutes = Math.floor(
        ((this.presaleStart ? distanceSaleStart : distancePresaleStart) % (1000 * 60 * 60)) /
        (1000 * 60)
      )
      this.countDown.seconds = Math.floor(
        ((this.presaleStart ? distanceSaleStart : distancePresaleStart) % (1000 * 60)) / 1000
      )
    },
    handleAccountsChange(accounts) {
      this.setWalletConnectionStatus(accounts)
    },
    connectMetaMask() {
      if (this.isWalletConnected) {
        this.$notify({
          group: 'app-notifications',
          type: 'success',
          text: 'Metamask is already connected'
        })
        this.switchWallet()
      } else {
        try {
          /* eslint-disable-next-line */
          FbEvents.CompleteRegistration()
        } catch (error) {
          console.log(error)
        }
        if (this.isMetaMaskInstalled) {
          this.ethereumRequest(requestMethods.callWalletConnection)
        } else {
          if (this.isMobile || this.bowser.platform.type !== 'desktop') {
            document.location.href = 'https://metamask.app.link/dapp/m101.space/'
          }
          this.$notify({
            group: 'app-notifications',
            type: 'error',
            title: 'MetaMask is not installed',
            text: `<a href="https://metamask.io/download.html" target="_blank">Download here</a>`
          })
        }
      }
    },
    async setWalletConnectionStatus(accounts) {
      this.isWalletConnected = !!accounts.length
      if (this.isWalletConnected) {
        if (this.currentWallet) {
          await this.logout()
        }
        this.currentWallet = accounts[0].toLowerCase()
        this.getOwner()
        // this.loginWeb3()
        try {
          /* eslint-disable-next-line */
          FbEvents.Contact()
        } catch (error) {
          console.log(error)
        }
      } else {
        this.currentWallet = null
        await this.logout()
      }
    },
    async checkAuth() {
      const response = await axios
        .post(this.apiUrl + '/web3-authcheck', {
          address: this.currentWallet
        }, this.headers)
        .catch(error => {
          console.log(error)
          // return error.response;
        })

      if (response) {
        return Promise.resolve(true)
      }

      return Promise.resolve(false)
    },
    async loginWeb3() {
      if (!this.isWalletConnected) {
        return Promise.resolve(true)
      }

      if (await this.checkAuth()) {
        return Promise.resolve(true)
      }

      let response = await axios.get(this.apiUrl + '/web3-signature').catch(error => {
        console.log(error)
      })

      const message = response.data

      const web3 = new Web3(window.ethereum)

      let signature = ''
      try {
        signature = await web3.eth.personal.sign(message, this.currentWallet)
      } catch (error) {
        return Promise.resolve(false)
      }

      response = await axios
        .post(this.apiUrl + '/web3-login', {
          message: message,
          signature: signature,
          address: this.currentWallet
        })
        .catch(error => {
          console.log(error)
        })

      if (response.data) {
        this.authToken = response.data.token
        Cookies.set('token', response.data.token, {
          expires: response.data.expires_in
        })
      }

      return Promise.resolve(true)
    },
    async logout() {
      if (!this.authToken) {
        return Promise.resolve(true)
      }

      await axios
        .post(this.apiUrl + '/web3-logout', {
          address: this.currentWallet
        }, this.headers)
        .catch(error => {
          console.log(error)
        })

      this.authToken = null
      Cookies.remove('token')

      return Promise.resolve(true)
    },
    async getOwner() {
      const response = await fetch(this.apiUrl + '/owner/' + this.currentWallet)

      if (response.ok) {
        const owner = await response.json()

        this.currentOwner = owner || {}

        this.starPoints = this.currentOwner.points_sum ? this.currentOwner.points_sum : 0
      }
    },
    async getToken() {
      const response = await axios.get(this.apiUrl + '/token/1').catch(error => {
        console.log(error)
      })

      if (response) {
        this.tokenDef = response.data
      }
    },
    ethereumRequest(method) {
      const ethereum = window.ethereum

      ethereum
        .request({
          method
        })
        .then(response => {
          if (method === 'eth_accounts') {
            this.setWalletConnectionStatus(response)
          }
        })
        .catch(error => {
          if (error.code === walletConnectionProcessStartedErrorCode) {
            this.$notify({
              group: 'app-notifications',
              type: 'error',
              text: 'Wallet connection process already started. Please finish or cancel it.'
            })
          }
          if (error.code === walletConnectionHeaderErrorCode) {
            // this.$notify({
            //   group: 'app-notifications',
            //   type: 'error',
            //   text: 'Something went wrong. Please, refresh page.'
            // })
          }
        })
    },
    switchWallet() {
      const ethereum = window.ethereum

      ethereum.request({
        method: requestMethods.callSwitchWallet,
        params: [{
          eth_accounts: {}
        }]
      })
        .then(() => {
          // this.ethereumRequest(requestMethods.getAccountsList)
        })
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    addListeners() {
      window.addEventListener('scroll', this.onScroll)
      window.addEventListener('resize', this.onResize)
      document.addEventListener('gesturestart', function (e) {
        e.preventDefault()
      })
    },
    onScroll(e) {
      this.windowTop = window.pageYOffset
    },
    onResize(state) {
      this.width = window.innerWidth
      this.height = window.innerHeight
      document.body.classList.remove('scroll-off')
    },
    handleInnerScroll(scrolled) {
      if (this.isTablet || this.isMobile) {
        return
      }
      scrolled.addEventListener('scroll', () => {
        const scrolledPosBottom = scrolled.offsetHeight + scrolled.scrollTop - scrolled.scrollHeight
        const scrolledPosTop = scrolled.offsetHeight + scrolled.scrollTop - scrolled.clientHeight

        if (scrolledPosBottom > -1) {
          window.mainSwiper.slideNext(600, true)
        }
        if (scrolledPosTop === 0) {
          window.mainSwiper.slidePrev(600, true)
        }
      })
    }
  },
  router,
  render: h => h(App)
}).$mount('#app')
