<template>
  <div id="voting" class="section voting bg-dark">
    <div class="container">
      <div class="voting__texts">
        <div ref="scrolled" class="voting__text-info">
          <h2 class="voting__title">DAO Voting</h2>
          <div class="voting__info">
            <p>
              All holders are eligible to hold a vote in the active proposals on the future
              decisions and other important matters in M101 Shelter. The more Survivors you hold on
              the eth address the more votes (WARPs) you have.
            </p>
          </div>
        </div>

        <div class="voting__panel">
          <span></span>
          <span></span>
          <p>
            Every holder has 1 WARP by default + 1 additional WARP per every M101 NFT on the
            address.
          </p>
          <p>
            Such math was made in order to lessen the gap in WARPs between whales and smaller
            holders.
          </p>
        </div>
      </div>
      <div class="voting__panel voting__panel--white">
        <span></span>
        <span></span>
        <p>
          <i>FYI:</i> You can either vote <i>“for”</i> or <i>“against”</i> and cannot revoke your
          vote once it`s made. Successfully completed voting requires
          <i>at least 20%</i> participants from the total number of holders and it closes right
          after the given time period expires.
        </p>
      </div>
      <VotingBlocks />
    </div>
  </div>
</template>

<script>
import VotingBlocks from '@/components/voting/VotingBlocks.vue'

export default {
  name: 'Voting',
  components: { VotingBlocks },
  props: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
