<template>
  <div id="starshop" class="section starshop bg-dark">
    <div class="container">
      <h2 class="starshop__title">
        <span> STAR SHOP </span>
        <small>
          Exchange your Star Points for various whitelist spots, exclusive NFTs and much more!
          <br />
          FYI: some items might be available only for a certain time period.
        </small>
      </h2>

      <div v-if="productsAvail.length" class="starshop__items">
        <div v-for="(productAvail, p) in productsAvail" :key="p" class="starshop__item">
          <span class="starshop__item-border-top"></span
          ><span class="starshop__item-border-bottom"></span
          ><span class="starshop__item-border-left"></span
          ><span class="starshop__item-border-right"></span>
          <div class="starshop__item-info">
            <span> {{ productAvail.title }} </span>
            <span> {{ productAvail.quantity }}/{{ productAvail.quantity_all }} </span>
          </div>
          <div class="starshop__item-img" @click.stop.prevent="showDetails(productAvail)">
            <a href="#" class="starshop__item-view">View details</a>
            <img :src="productAvail.image_url" alt="image" />
          </div>
          <div class="starshop__item-btnwrap">
            <button class="button button--yellow" @click="showPurchase(productAvail)">
              <span></span><span></span><span></span><span></span><span></span><span></span> BUY FOR
              {{ productAvail.price }} POINTS
            </button>
          </div>
        </div>
      </div>

      <div class="starshop__banner">
        <div class="starshop__banner-texts">
          <h2>
            CLICK HERE TO CHECK OUT YOUR
            <router-link to="/starpoints">STAR POINTS</router-link> BALANCE.
          </h2>
          <h2>
            VISIT <router-link to="/spacenomics">SPACENOMICS PAGE</router-link> TO FIND OUT MORE
            ABOUT HOLDERS PRIVILEGES.
          </h2>
          <h2>NOT A HOLDER?</h2>
        </div>
        <div class="starshop__banner-buttons">
          <a
            href="https://opensea.io/collection/m101survivors"
            target="_blank"
            class="button button--pink"
          >
            <span></span><span></span><span></span><span></span><span></span><span></span> BUY ON
            OPENSEA
          </a>
          <router-link to="/" class="button button--pink">
            <span></span><span></span><span></span><span></span><span></span><span></span> MINT FOR
            0.02 ETH
          </router-link>
        </div>
      </div>

      <div v-if="productsSold.length" class="starshop__sold">
        <h2 class="starshop__sold-title">Sold out</h2>
        <div class="starshop__sold-items">
          <div v-for="(productSold, p) in productsSold" :key="p" class="starshop__sold-item">
            <div class="starshop__sold-img">
              <div class="minting__mint-bg"></div>
              <div class="minting__mint-bg"></div>
              <div class="starshop__sold-imgwrap">
                <img :src="productSold.image_url" alt="minted" />
              </div>
            </div>
            <div class="starshop__sold-count">
              <span>
                {{ productSold.title }}
              </span>
              <span>
                Sold: {{ productSold.quantity_ordered }}/{{ productSold.quantity_ordered }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="starshop__disclaimer">
        *DYOR, you remain responsible for your financial decisions.
      </div>
    </div>

    <div v-if="detailsModal.show && detailsModal.product" class="modal">
      <div class="modal__overlay" @click.self="closeDetails()">
        <div class="modal__body bg-pink">
          <button class="button button--close-modal" @click="closeDetails()">&times;</button>
          <div class="modal__texts">
            <h3 class="modal__title">{{ detailsModal.product.title }}</h3>
            <div class="modal__text">
              <small v-html="detailsModal.product.content"></small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="purchaseModal.show && purchaseModal.product" class="modal">
      <div class="modal__overlay" @click.self="closePurchase()">
        <div class="modal__body bg-pink">
          <button class="button button--close-modal" @click="closePurchase()">&times;</button>
          <div class="modal__texts">
            <h3 class="modal__title">Balance: {{ starPoints }} Star Points</h3>
            <h3 class="modal__title">
              Do you confirm this purchase for {{ purchaseModal.product.price }} SP?
            </h3>
            <div class="modal__btns">
              <button
                class="button button--search button--yellow"
                @click="buy(purchaseModal.product)"
              >
                <span></span><span></span><span></span><span></span><span></span><span></span>
                Yes
              </button>
              <button class="button button--search button--yellow" @click="closePurchase()">
                <span></span><span></span><span></span><span></span><span></span><span></span>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import Web3 from 'web3'

export default {
  name: 'StarShop',
  props: {},
  data() {
    return {
      products: [],
      inProcess: false,
      detailsModal: {
        product: null,
        show: false
      },
      purchaseModal: {
        product: null,
        show: false
      }
    }
  },
  computed: {
    owner() {
      return this.$root.currentOwner
    },
    starPoints() {
      return this.$root.starPoints
    },
    currentWallet() {
      return this.$root.currentWallet
    },
    isWalletConnected() {
      return this.$root.isWalletConnected
    },
    productsAvail() {
      return this.products.filter(product => {
        return !product.is_sold
      })
    },
    productsSold() {
      return this.products.filter(product => {
        return product.is_sold
      })
    }
  },
  created() {
    this.getProducts()
  },
  mounted() {},
  methods: {
    showDetails(product) {
      this.detailsModal.product = product
      this.detailsModal.show = true
    },
    closeDetails() {
      this.detailsModal.product = null
      this.detailsModal.show = false
    },
    showPurchase(product) {
      if (!this.connectCheck) {
        return
      }

      this.purchaseModal.product = product
      this.purchaseModal.show = true
    },
    closePurchase() {
      this.purchaseModal.product = null
      this.purchaseModal.show = false
    },
    async buy(product) {
      if (!this.connectCheck || this.inProcess) {
        return
      }

      this.inProcess = true

      // if (product.addresses.includes(this.currentWallet)) {
      //   this.$notify({
      //     group: 'check-notifications',
      //     type: 'error',
      //     text: `${product.title} already purchased`
      //   })
      //   this.inProcess = false
      //   return
      // }

      if (this.starPoints < product.price) {
        this.$notify({
          group: 'check-notifications',
          type: 'error',
          text: 'Not enought star points'
        })
        this.inProcess = false
        return
      }

      await this.$root.loginWeb3()

      const data = new FormData()
      data.append('id', product.id)
      data.append('address', this.currentWallet)

      const response = await axios
        .post(this.$root.apiUrl + '/order', data, this.$root.headers)
        .catch(error => {
          console.log(error)
        })

      if (response.data.success) {
        this.$notify({
          group: 'check-notifications',
          type: 'success',
          text: 'Your order has been sent'
        })
        this.getProducts()
        this.$root.getOwner()
        this.closePurchase()
      }

      this.inProcess = false
    },
    connectCheck() {
      if (!this.isWalletConnected) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'Please connect your Metamask wallet.'
        })
        return false
      }

      return true
    },
    async getProducts() {
      const response = await axios.get(this.$root.apiUrl + '/products').catch(error => {
        console.log(error)
      })

      this.products = response.data
    }
  }
}
</script>
