<template>
  <div class="limited bg-pink">
    <div class="container">
      <div class="limited__body">
        <div class="limited__inner">
          <div class="limited__texts">
            <p class="limited__title">M101 3D LIMITED COLLECTION</p>
            <p class="limited__text">
              Being an M101 holder now grants you an absolutely new privilege - access to a 3D
              modeled collection! We’re taking the most popular and rare Survivors and recreating
              them in a new way: adding more color and more details.
            </p>
            <p class="limited__text">
              Check out the very first one - the famous <span>Saros</span> in the reimagined
              full-body form - he is <span>#1 in the rarity ranking</span> of Season 1.
            </p>
          </div>
          <div class="limited__img-wrapper">
            <div v-if="!$root.isMobile" class="limited__img">
              <video class="limited__video" poster="@/assets/img/saros.png" autoplay loop muted>
                <source src="@/assets/img/saros.mp4" type="video/mp4" />
                <source src="@/assets/img/saros.webm" type="video/webm" />
              </video>
            </div>
            <div v-else class="limited__img">
              <img src="@/assets/img/saros.png" alt="Saros" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeasonTwo',
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
