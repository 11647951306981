var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section faq bg-dark",class:{ 'bg-dark': _vm.$root.isTablet || _vm.$root.isMobile },attrs:{"id":"faq"}},[_c('div',{staticClass:"faq__bg-pattern"}),_c('div',{staticClass:"container"},[_c('h2',{staticClass:"faq__title"},[_vm._v("FAQ")]),_c('div',{staticClass:"faq__content"},[_c('div',{staticClass:"faq__slider"},[_c('swiper',{key:_vm.swiperKey,ref:"innerSwiper",staticClass:"swiper",attrs:{"options":_vm.swiperOptions},on:{"ready":_vm.handleSwiperReadied}},[_c('swiper-slide',[_c('img',{attrs:{"src":require("@/assets/img/slide_faq_1.png"),"alt":"slide"}})]),_c('swiper-slide',[_c('img',{attrs:{"src":require("@/assets/img/slide_faq_2.png"),"alt":"slide"}})]),_c('swiper-slide',[_c('img',{attrs:{"src":require("@/assets/img/slide_faq_3.png"),"alt":"slide"}})])],1)],1),_c('div',{ref:"scrolled",staticClass:"faq__texts"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"faq__info"},[_c('p',[_vm._v(" Find out more about "),_c('router-link',{attrs:{"to":"/spacenomics"}},[_vm._v("SPACENOMICS")]),_vm._v(" and "),_c('router-link',{attrs:{"to":"/starshop"}},[_vm._v("STAR SHOP")]),_vm._v(". ")],1)]),_vm._m(5),_c('div',{staticClass:"faq__info"},[_c('p',[_vm._v("Please check out our own "),_c('router-link',{attrs:{"to":"/ranking"}},[_vm._v("RANKING")]),_vm._v(" system.")],1)]),_vm._m(6),_c('div',{staticClass:"faq__info"},[_c('p',[_vm._v(" Of course! If you're an M101 holder you can participate in active "),_c('router-link',{attrs:{"to":"/voting"}},[_vm._v("DAO voting")]),_vm._v(". ")],1)]),_vm._m(7),_c('div',{staticClass:"faq__info"},[_c('p',[_vm._v(" Please visit a special page dedicated to "),_c('router-link',{attrs:{"to":"/season-2"}},[_vm._v("Season 2")]),_vm._v(". ")],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"faq__subtitle"},[_c('span',[_vm._v("What is M101 Shelter?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"faq__info"},[_c('p',[_vm._v(" The M101 Shelter is a NFT collection of generative side-profile characters with over 600 hand-drawn attributes and more than 2.800 color schemes, which are ready to warp through Galaxy! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"faq__subtitle"},[_c('span',[_vm._v("WHAT IS THE PRICE TO MINT 1 SURVIVOR?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"faq__info"},[_c('p',[_vm._v("Special price is "),_c('i',[_vm._v("25% off")]),_vm._v(" or "),_c('i',[_vm._v("0.02 ETH!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"faq__subtitle"},[_c('span',[_vm._v("WHAT IS THE SPECIAL "),_c('i',[_vm._v("UTILITY FOR M101 HOLDERS?")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"faq__subtitle"},[_c('span',[_vm._v("HOW CAN I CHECK THE RARITY LEVEL OF MY SEASON 1 NFT?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"faq__subtitle"},[_c('span',[_vm._v("DO YOU HAVE A DAO?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"faq__subtitle"},[_c('span',[_vm._v("WHERE CAN I SEE THE LIST OF SEASON 2 UTILITY?")])])
}]

export { render, staticRenderFns }