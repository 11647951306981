<template>
  <div class="smart bg-dark">
    <div class="container">
      <div class="smart__inner">
        <div class="smart__info">
          <div class="smart__texts">
            <p class="smart__title">NEW ADVANCED SMART CONTRACT</p>
            <p class="smart__text">
              We decided to use the innovative <span>ERC-721A</span> Standard instead of the
              previous ERC-721. How come? It solves the most vital problem in NFT space - the gas
              overspending. Check the chart below and compare the advantage (assuming 150 gwei as
              average gas fee):
            </p>
          </div>
          <div class="smart__img">
            <img src="@/assets/img/astra.png" alt="Astra" />
          </div>
        </div>
        <div class="table">
          <table class="table__body">
            <thead>
              <tr class="table__header">
                <th
                  v-for="(column, index) in table.columns"
                  :key="index"
                  class="table__header-item"
                >
                  <p v-html="column.title"></p>
                </th>
              </tr>
            </thead>
            <tbody class="table__items">
              <div>
                <tr v-for="(row, index) in table.rows" :key="index" class="table__row">
                  <td v-for="(td, t) in row" :key="t" class="table__item">
                    <p>{{ td }}</p>
                  </td>
                </tr>
              </div>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Smart',
  data() {
    return {
      table: {
        columns: [
          {
            title: 'NFTS'
          },
          {
            title: 'ERC-721 Gas used<span>(gwei)</span>'
          },
          {
            title: 'ERC-721 Gas fee <span> (ETH)</span>'
          },
          {
            title: 'ERC-721A Gas used <span>(gwei)</span>'
          },
          {
            title: 'ERC-721A Gas fee <span>(ETH)</span>'
          }
        ],
        rows: [
          ['1', '154.814', '0.023 ETH', '76.690', '0.012 ETH'],
          ['2', '270.339', '0.041 ETH', '78.819', '0.012 ETH'],
          ['3', '384.864', '0.058 ETH', '80.948', '0.012 ETH'],
          ['4', '501.389', '0.075 ETH', '83.077', '0.012 ETH'],
          ['5', '616.914', '0.093 ETH', '85.206', '0.013 ETH'],
          ['6', '724.232', '0.109 ETH', '89.347', '0.013 ETH'],
          ['7', '839.719', '0.126 ETH', '92.102', '0.014 ETH'],
          ['8', '955.206', '0.143 ETH', '93.857', '0.014 ETH'],
          ['9', '1.010.639', '0.161 ETH', '95.081', '0.014 ETH'],
          ['10', '1.186.180', '0.178 ETH', '97.389', '0.015 ETH']
        ]
      }
    }
  }
}
</script>

<style></style>
