<template>
  <section class="details">
    <div class="details__inner">
      <div class="details__rocket">
        <img :src="img.src" :alt="img.alt" />
      </div>
      <div class="details__text">
        <p>
          MORE DETAILS ABOUT THE SEASON 2 ARE
          <router-link to="/season-2">HERE</router-link><br />
          DON'T MISS OUT THE LATEST ANNOUNCEMENTS ON OUR SOCIALS
        </p>
        <p class="details__socials">
          <a href="https://twitter.com/M101_Shelter" target="_blank" class="header__social-link">
            <svg width="50" height="50" aria-hidden="true"><use xlink:href="#twitter"></use></svg>
          </a>
          <a href="http://discord.gg/m101" target="_blank" class="header__social-link">
            <svg width="50" height="50" aria-hidden="true"><use xlink:href="#discord"></use></svg>
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Details',
  data() {
    return {
      img: {
        src: require('@/assets/img/rocket.png'),
        alt: 'rocket'
      }
    }
  }
}
</script>
