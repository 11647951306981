<template>
  <div id="spacenomics" class="section spacenomics">
    <div class="spacenomics__info bg-pink">
      <div class="container">
        <h2 class="spacenomics__title">ADVANCED SPACENOMICS</h2>
        <!-- <div class="spacenomics__rocket">
          <div class="spacenomics__spaceship"></div>
          <div class="spacenomics__rocket-text">
            DON’T MISS OUT THE CHANCE TO GET ALL M101 HOLDER’S PRIVILEGES!
          </div>
          <a
            class="button button--buy-opensea button--yellow"
            href="https://opensea.io/collection/m101survivors"
            target="_blank"
          >
            <span></span><span></span><span></span><span></span><span></span><span></span> BUY ON
            OPENSEA</a
          >
        </div> -->
        <div class="spacenomics__panelwrap">
          <div class="spacenomics__panel">
            <span></span>
            <span></span>
            <h3 class="spacenomics__panel-title">M101 HOLDERS’ REWARD & PRIVILEGES</h3>
            <div class="spacenomics__panel-text">
              <p>
                EACH HOLDER OF M101 SEASON 1 AND SEASON 2 RECEIVES DAILY 2 STAR POINTS PER 1 NFT.
                ALSO, BEING A HOLDER ALLOWS YOU TO ACCESS THE
                <router-link to="/starshop">STAR SHOP</router-link>.
              </p>
              <p>WANT TO BECOME A HOLDER?</p>
              <div class="spacenomics__panel-btns">
                <a
                  class="button button--buy-opensea button--yellow"
                  href="https://opensea.io/collection/m101survivors"
                  target="_blank"
                >
                  <span></span><span></span><span></span><span></span><span></span><span></span>
                  BUY S1
                </a>
                <p>OR</p>
                <router-link class="button button--buy-opensea button--yellow" to="/">
                  <span></span><span></span><span></span><span></span><span></span><span></span>
                  MINT S2
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spacenomics__details">
      <div class="container">
        <div class="spacenomics__arrow spacenomics__arrow--rotated"></div>
        <div class="spacenomics__desc">
          <p>
            STAR POINTS IS THE ESSENTIAL PART OF M101 SPACENOMICS AND BRINGS MAIN UTILITY FOR
            HOLDERS.
          </p>
        </div>
        <router-link class="button button--white button--starpoints" to="/starpoints">
          <span></span><span></span><span></span><span></span><span></span><span></span> TO CHECK
          YOUR STAR POINTS BALANCE CLICK HERE
        </router-link>
        <!-- <div class="spacenomics__content">
          <div class="spacenomics__item">
            <span class="spacenomics__item-bg"></span>
            <span class="spacenomics__item-bg"></span>
            <h3 class="spacenomics__item-title">FOREVER-RUNNING EARNINGS</h3>
            <div class="spacenomics__div"></div>
            <h3 class="spacenomics__item-subtitle">FOR MINTING M101 NFT</h3>
            <div class="spacenomics__item-info">
              <p>
                Once you have minted M101 NFT and sold it on OpenSea - you will start to gain 1.51%
                of its EVERY sale from now on!
              </p>
              <div class="spacenomics__div"></div>
              <p>
                The calculation of your commission of every further sale is automatic: you don`t
                have to do anything special - once OpenSea sends the royalties you`re getting your
                share as M101 OG.
              </p>
              <div class="spacenomics__div"></div>
              <p>
                <span>Example:</span> Let's assume you sold your M101 to someone on OpenSea, and
                then this person sells it for 10 ETH - your share will be 0.151 ETH. Doesn`t matter
                how many times the NFT you minted once is getting sold or how many you minted in
                total: you definitely will receive your share from every deal.
              </p>
            </div>
          </div>
          <div class="spacenomics__item">
            <span class="spacenomics__item-bg"></span>
            <span class="spacenomics__item-bg"></span>
            <h3 class="spacenomics__item-title">FOREVER-RUNNING EARNINGS</h3>
            <div class="spacenomics__div"></div>
            <h3 class="spacenomics__item-subtitle">FOR HOLDING M101 NFT</h3>
            <div class="spacenomics__item-info">
              <p>
                Once you obtain (buy on OpenSea or mint) M101 NFT you`re receiving 20 Star Points
                per 1 Survivor daily. The more SP you have the bigger share from 1.51% our OpenSea
                royalties you will receive.
              </p>
              <div class="spacenomics__div"></div>
              <p>
                The calculation of your SP is automatic: our smart contract is getting parsed a few
                times per day to verify every owner address and number of M101 tokens on it.
              </p>
              <div class="spacenomics__div"></div>
              <p>
                <span>Example:</span> Let`s assume M101 trading volume on OpenSea per month is 1000
                ETH. The 1.51% of the royalties pool that belongs to holders is 15.1 ETH. The more
                Star Points you have the bigger your percentage in that share. Hypothetically, if
                you hold 50 NFT and got 30K Star Points for 30 days - and it`s makes up 30% of the
                total number of Star Points distributed. Your share of the royalties is 4.53 ETH per
                month.
              </p>
            </div>
          </div>
        </div> -->
        <div class="spacenomics__arrow"></div>
        <div class="spacenomics__desc">
          <p>
            USE YOUR STAR POINTS IN OUR STAR SHOP,<br />
            THE SPACE OF
            <span>COLLABORATION WITH OTHER NFT PROJECTS</span>,<br />
            WHERE YOU CAN PURCHASE WHITELIST SPOTS, NFTS, AND MUCH MORE INTERESTING ITEMS.
          </p>
        </div>
        <router-link to="/starshop" class="button button--white button--starpoints">
          <span></span><span></span><span></span><span></span><span></span><span></span> TO SEE THE
          STAR SHOP CLICK HERE
        </router-link>
        <div class="spacenomics__panel">
          <span></span>
          <span></span>
          <h3 class="spacenomics__panel-title">
            <a href="http://discord.gg/m101" class="spacenomics__panel-link" target="_blank">
              DISCORD
            </a>
            M101 COIN:
          </h3>
          <div class="spacenomics__panel-text">
            MULTIPLY your prizes by participating in numerous Discord activities: Twitter raids,
            games, community life, quests and contests! The more points you have - the more you can
            spend on: FREE MERCH, M101 WHITELIST spot, M101 PRINT, NFTs, ETH & much more!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spacenomics',
  data() {
    return {}
  },
  mounted() {}
}
</script>
