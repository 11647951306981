<template>
  <main class="bg-main">
    <Header :sections="sections" :current-section="currentSection" />
    <section id="error-page" class="section error-page bg-pink">
      <div class="container">
        <div class="error-page__content">
          <img src="@/assets/img/error.png" alt="404" />
          <router-link class="button button--yellow" to="/">
            <span></span><span></span><span></span><span></span><span></span><span></span>
            Go to home page
          </router-link>
        </div>
      </div>
    </section>
    <Footer :sections-length="sections.length" :current-section="currentSection" />
  </main>
</template>

<script>
import Header from '@/components/common/Header'
import Footer from '@/components/common/Footer'

export default {
  name: 'ErrorPage',
  components: { Header, Footer },
  props: {
    sections: {
      type: Array,
      required: true
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  data() {
    return {}
  },
  computed: {
    currentSection() {
      return this.sections[0]
    }
  },
  methods: {}
}
</script>
