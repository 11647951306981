<template>
  <div class="reward bg-pink">
    <div class="container">
      <div class="reward__rocket">
        <div class="reward__spaceship"></div>
        <!-- <div class="reward__texts">
          <p class="reward__title">M101 OG HOLDERS’ REWARD</p>
          <p class="reward__rocket-text">
            HOLDERS OF M101 SEASON 1 ARE ELIGIBLE TO RECEIVE A PERSONAL SHARE FROM
            <span>50% OF ALL SEASON 2 PROCEEDS </span>DEPENDING ON THE NUMBER OF TOKENS THEY HOLD.
          </p>
        </div> -->

        <Minting class="mint__content--reward" />
      </div>

      <div v-if="!saleStart" class="reward__blocks">
        <div class="reward__block">
          <span class="reward__border-top"></span>
          <span class="reward__border-bottom"></span>
          <span class="reward__border-left"></span>
          <span class="reward__border-right"></span>
          <div class="reward__block-inner">
            <div class="reward__block-texts">
              <div class="reward__block-title">
                DON’T MISS OUT THE CHANCE TO GET ALL M101 HOLDER’S PRIVILEGES!
              </div>
              <a
                class="button button--white button--reward-opensea"
                href="https://allstar.m101.space/"
                target="_blank"
              >
                <span></span><span></span><span></span><span></span><span></span><span></span>
                BUY ON OPENSEA
              </a>
            </div>
          </div>
        </div>
        <div class="reward__block">
          <span class="reward__border-top"></span>
          <span class="reward__border-bottom"></span>
          <span class="reward__border-left"></span>
          <span class="reward__border-right"></span>
          <div class="reward__block-inner">
            <div class="reward__block-texts">
              <div class="reward__block-title">
                GET ON A SEASON 2 WHITELIST WHILE THE SPOTS ARE AVAILABLE!
              </div>
              <router-link
                class="button button--yellow button--reward"
                to="/whitelist"
                target="_blank"
              >
                <span></span><span></span><span></span><span></span><span></span><span></span>
                WHITELIST ME
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <p class="reward__follow">
        Follow our
        <a class="reward__link" href="https://twitter.com/M101_Shelter" target="_blank">
          <svg width="45" height="45" aria-hidden="true">
            <use xlink:href=" #twitter"></use>
          </svg>
        </a>
        <a class="reward__link" href="http://discord.gg/m101" target="_blank">
          <svg width="45" height="45" aria-hidden="true">
            <use xlink:href=" #discord"></use>
          </svg>
        </a>
        FOR THE LATEST UPDATES!
      </p>
    </div>
  </div>
</template>
<script>
import Minting from '@/components/mint/Minting.vue'

export default {
  name: 'Reward',
  components: { Minting },
  data() {
    return {}
  },
  computed: {
    presaleStart() {
      return this.$root.presaleStart
    },
    presaleEnd() {
      return this.$root.presaleEnd
    },
    saleStart() {
      return this.$root.saleStart
    }
  }
}
</script>
