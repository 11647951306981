<template>
  <div class="allstars bg-dark">
    <span class="allstars__border-top"></span>
    <span class="allstars__border-bottom"></span>
    <span class="allstars__border-left"></span>
    <span class="allstars__border-right"></span>
    <div class="allstars__inner">
      <swiper
        ref="innerSwiper"
        :key="swiperKey"
        class="swiper allstars__img-wrapper"
        :options="swiperOptions"
        @ready="handleSwiperReadied"
      >
        <swiper-slide v-for="(item, i) in slides" :key="i" class="allstars__img">
          <img :src="item.src" alt="slide" />
        </swiper-slide>
      </swiper>

      <div class="allstars__texts">
        <div class="allstars__title allstars__title--big">M101 ALL-STAR</div>
        <div class="allstars__title">EXTRA LIMITED HAND-DRAWN NFTS CREATED FOR M101 HOLDERS</div>
        <a
          class="button button--yellow button--stars"
          href="https://allstar.m101.space/"
          target="_blank"
        >
          <span></span><span></span><span></span><span></span><span></span><span></span>
          CHECK OUT
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'Allstars',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: false,
        simulateTouch: true,
        centeredSlides: true,
        // centeredSlidesBounds: true,
        autoHeight: true,
        speed: 600,
        effect: 'slide',
        loop: true,
        autoplay: {
          delay: 2000
        }
      },
      swiperKey: 0,
      swiper: null,
      slides: [
        {
          src: require('@/assets/img/kianu.jpg')
        },
        {
          src: require('@/assets/img/aoki.png')
        },
        {
          src: require('@/assets/img/snoop.png')
        },
        {
          src: require('@/assets/img/shinoda.png')
        },
        {
          src: require('@/assets/img/kobe.jpg')
        }
      ]
    }
  },
  watch: {
    '$root.width'(newV, oldV) {
      this.swiperKey = newV
      this.swiper.update()
      this.$refs.innerSwiper.$swiper.update()
      setTimeout(() => {
        this.swiperKey = newV + 1
        this.swiper.update()
        this.$refs.innerSwiper.$swiper.update()
      }, 400)
    }
  },
  methods: {
    handleSwiperReadied(swiper) {
      this.swiper = swiper
    }
  }
}
</script>
