<template>
  <div id="team" class="section team bg-pink">
    <div class="team__bg-pattern"></div>
    <div class="container">
      <div class="team__content">
        <div class="team__img">
          <img src="@/assets/img/rocket.png" alt="rocket" />
        </div>
        <div ref="scrolled" class="team__texts">
          <h2 class="team__title">TEAM</h2>
          <div class="team__items">
            <div v-for="(item, i) in items" :key="i" class="team__item">
              <img
                class="team__item-image"
                :src="$root.isMobile ? item.image_mob : item.image"
                :alt="item.name"
              />
              <div class="team__item-texts">
                <h3 class="team__item-title">{{ item.name }}</h3>
                <div class="team__item-info">{{ item.text }}</div>
                <!-- <div v-if="item.socials" class="team__item-social">
                  <a
                    v-for="(social, s) in item.socials"
                    :key="s"
                    :href="social.url"
                    class="header__social-link"
                    target="_blank"
                  >
                    <svg width="40" height="40" aria-hidden="true">
                      <use :xlink:href="social.icon"></use>
                    </svg>
                  </a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Team',
  data() {
    return {
      items: [
        {
          image: require('@/assets/img/sanchezz.png'),
          image_mob: require('@/assets/img/sanchezz.png'),
          name: 'Sanchezz',
          text: 'Artist, Illustrator.',
          socials: [
            {
              icon: '#twitter',
              url: 'https://twitter.com/sanchezz111'
            },
            {
              icon: '#behance',
              url: 'https://www.behance.net/sanchezz111'
            }
          ]
        },
        {
          image: require('@/assets/img/n_generation.png'),
          image_mob: require('@/assets/img/n_generation.png'),
          name: 'N_Generation (X11nz/2)',
          text: 'Project Lead, author',
          socials: [
            {
              icon: '#twitter',
              url: 'https://twitter.com/Dastin_U'
            }
          ]
        },
        {
          image: require('@/assets/img/mayhem.png'),
          image_mob: require('@/assets/img/mayhem.png'),
          name: 'Mayhem Rebel',
          text: 'Communications, marketing',
          socials: [
            {
              icon: '#twitter',
              url: 'https://twitter.com/M101_Shelter'
            }
          ]
        },
        {
          image: require('@/assets/img/synth.png'),
          image_mob: require('@/assets/img/synth.png'),
          name: 'Synth Rider',
          text: 'Discord, community relations'
          // socials: [
          //   {
          //     icon: '#discord',
          //     url: 'https://discord.com/invite/m101'
          //   }
          // ]
        },
        {
          image: require('@/assets/img/xandro.png'),
          image_mob: require('@/assets/img/xandro.png'),
          name: 'Xandro',
          text: 'Back-end, Tech development'
        },
        {
          image: require('@/assets/img/vicky.png'),
          image_mob: require('@/assets/img/vicky.png'),
          name: 'Vicky-101',
          text: 'Front-end design, website'
        }
      ]
    }
  },
  mounted() {}
}
</script>
