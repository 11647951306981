<template>
  <div
    id="faq"
    class="section faq bg-dark"
    :class="{ 'bg-dark': $root.isTablet || $root.isMobile }"
  >
    <div class="faq__bg-pattern"></div>
    <div class="container">
      <h2 class="faq__title">FAQ</h2>
      <div class="faq__content">
        <div class="faq__slider">
          <swiper
            ref="innerSwiper"
            :key="swiperKey"
            class="swiper"
            :options="swiperOptions"
            @ready="handleSwiperReadied"
          >
            <swiper-slide>
              <img src="@/assets/img/slide_faq_1.png" alt="slide" />
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/img/slide_faq_2.png" alt="slide" />
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/img/slide_faq_3.png" alt="slide" />
            </swiper-slide>
          </swiper>
        </div>
        <div ref="scrolled" class="faq__texts">
          <h3 class="faq__subtitle"><span>What is M101 Shelter?</span></h3>
          <div class="faq__info">
            <p>
              The M101 Shelter is a NFT collection of generative side-profile characters with over
              600 hand-drawn attributes and more than 2.800 color schemes, which are ready to warp
              through Galaxy!
            </p>
          </div>
          <h3 class="faq__subtitle">
            <span>WHAT IS THE PRICE TO MINT 1 SURVIVOR?</span>
          </h3>
          <div class="faq__info">
            <p>Special price is <i>25% off</i> or <i>0.02 ETH!</i></p>
          </div>
          <h3 class="faq__subtitle">
            <span>WHAT IS THE SPECIAL <i>UTILITY FOR M101 HOLDERS?</i></span>
          </h3>
          <div class="faq__info">
            <p>
              Find out more about <router-link to="/spacenomics">SPACENOMICS</router-link> and
              <router-link to="/starshop">STAR SHOP</router-link>.
            </p>
          </div>
          <h3 class="faq__subtitle">
            <span>HOW CAN I CHECK THE RARITY LEVEL OF MY SEASON 1 NFT?</span>
          </h3>
          <div class="faq__info">
            <p>Please check out our own <router-link to="/ranking">RANKING</router-link> system.</p>
          </div>
          <h3 class="faq__subtitle">
            <span>DO YOU HAVE A DAO?</span>
          </h3>
          <div class="faq__info">
            <p>
              Of course! If you're an M101 holder you can participate in active
              <router-link to="/voting">DAO voting</router-link>.
            </p>
          </div>
          <h3 class="faq__subtitle">
            <span>WHERE CAN I SEE THE LIST OF SEASON 2 UTILITY?</span>
          </h3>
          <div class="faq__info">
            <p>
              Please visit a special page dedicated to
              <router-link to="/season-2">Season 2</router-link>.
            </p>
          </div>
          <!-- <a class="faq__button button button--yellow" href="javascript:;" target="_blank">
            <span></span><span></span><span></span><span></span><span></span><span></span> Find
            answers on these and other questions on our Discord
            <svg>
              <use xlink:href="#discord-square"></use>
            </svg>
          </a> -->
          <!-- <div class="faq__button-wrap">
            <span>Find answers on these and other questions on our</span>
            <a class="faq__button button button--yellow" href="javascript:;" target="_blank">
              Discord
              <span></span><span></span><span></span><span></span><span></span><span></span>
              <svg>
                <use xlink:href="#discord-square"></use>
              </svg>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'Faq',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 0,
        mousewheel: false,
        simulateTouch: true,
        autoHeight: true,
        speed: 600,
        effect: 'slide',
        loop: true,
        autoplay: {
          delay: 2000
        }
      },
      swiper: null,
      swiperKey: 0
    }
  },
  computed: {
    presaleStart() {
      return this.$root.presaleStart
    },
    presaleEnd() {
      return this.$root.presaleEnd
    },
    saleStart() {
      return this.$root.saleStart
    }
  },
  watch: {
    '$root.width'(newV, oldV) {
      this.swiperKey = newV
      this.swiper.update()
      this.$refs.innerSwiper.$swiper.update()
      setTimeout(() => {
        this.swiperKey = newV + 1
        this.swiper.update()
        this.$refs.innerSwiper.$swiper.update()
      }, 400)
    }
  },
  mounted() {},
  methods: {
    handleSwiperReadied(swiper) {
      this.swiper = swiper
    }
  }
}
</script>
