<template>
  <main class="bg-main">
    <Header :swiper="swiper" :sections="sections" :current-section="currentSection" />
    <Video v-if="!presaleStart" />
    <Main />
    <!-- <Starpoints /> -->
    <Races />
    <Faq />
    <Story />
    <Team />
    <Footer
      :swiper="swiper"
      :active-index="activeIndex"
      :sections-length="sectionsLength"
      :current-section="currentSection"
      :prev-section="prevSection"
      :next-section="nextSection"
    />
  </main>
</template>

<script>
import Header from '@/components/common/Header'
import Footer from '@/components/common/Footer'
import Video from '@/components/home/Video'
import Main from '@/components/home/Main'
import Races from '@/components/home/Races'
import Story from '@/components/home/Story'
// import Starpoints from '@/components/points/Starpoints'
import Team from '@/components/home/Team'
import Faq from '@/components/home/Faq'

export default {
  name: 'HomePage',
  components: {
    Header,
    Video,
    Main,
    Races,
    Story,
    // Starpoints,
    Team,
    Faq,
    Footer
  },
  props: {
    sections: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      swiperOptions: {
        direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 0,
        simulateTouch: false,
        autoHeight: true,
        speed: 600,
        effect: 'coverflow',
        coverflowEffect: {
          depth: 100,
          rotate: 30,
          slideShadows: false
        },
        touchReleaseOnEdges: true,
        mousewheel: false
      },
      swiper: null,
      activeIndex: 0
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  computed: {
    presaleStart() {
      return this.$root.presaleStart
    },
    presaleEnd() {
      return this.$root.presaleEnd
    },
    saleStart() {
      return this.$root.saleStart
    },
    currentSection() {
      return this.sections[this.activeIndex]
    },
    prevSection() {
      return this.sections[this.activeIndex - 1]
    },
    nextSection() {
      return this.sections[this.activeIndex + 1]
    },
    sectionsLength() {
      return this.sections.length
    },
    swiperKey() {
      return this.$root.width + this.$root.height
    }
  },
  watch: {
    presaleStart(newV, oldV) {
      setTimeout(() => {
        this.handleTop()
      }, 100)
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    this.handleTop()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onSlideChange() {
      this.activeIndex = this.swiper.activeIndex
    },
    handleSwiperReadied(swiper) {
      this.swiper = window.mainSwiper = swiper
    },
    onScroll() {
      this.handleTop()
    },
    handleTop() {
      const top = window.scrollY

      this.sections.forEach((section, s) => {
        if (!section.id) {
          return
        }
        const el = document.querySelector(section.id)
        if (!el) {
          return
        }
        const elTop = el.offsetTop
        const height = el.offsetHeight

        if (top + 110 > elTop && top + 100 < elTop + height) {
          this.activeIndex = s
        }
      })
    },
    onSlideChangeTransitionEnd() {
      this.swiperRecalc()
    },
    swiperRecalc() {
      const el = this.$refs.mainSwiper.$el
      const activeSlide = el.querySelector('.swiper-slide.swiper-slide-active')
      const wrapper = el.querySelector('.swiper-wrapper')
      activeSlide.style.height = 'auto'
      const height = activeSlide.clientHeight

      activeSlide.style.height = height + 'px'
      wrapper.style.height = height + 'px'

      if (this.swiper.activeIndex === 0) {
        this.swiper.mousewheel.enable()
        this.swiper.allowTouchMove = true
        el.style.overflow = 'hidden'
      } else {
        this.swiper.mousewheel.disable()
        this.swiper.allowTouchMove = false
        el.style.overflow = 'visible'
        this.$scrollTo('#races', 5)
      }
    }
  }
}
</script>
