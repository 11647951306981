<template>
  <header class="header" :class="currentSection.class">
    <div class="container">
      <div v-if="!$root.isMobile && !$root.isTablet" class="header__content">
        <div class="header__sections">
          <a class="header__logo" href="#" @click.prevent="goTo('#main')">
            <svg width="91" height="90" aria-hidden="true" :style="{ fill: logoColor }">
              <use xlink:href="#logo"></use>
            </svg>
          </a>
          <a
            v-for="(link, i) in social_links"
            :key="i + 10"
            class="header__social-link"
            :href="link.href"
            target="_blank"
          >
            <svg width="40" height="40" aria-hidden="true">
              <use :xlink:href="link.icon"></use>
            </svg>
            <template v-if="link.tag">
              <span
                class="header__social-tag"
                :class="
                  currentSection.class === 'bg-pink'
                    ? 'header__social-tag--blue'
                    : 'header__social-tag--pink'
                "
                v-html="link.tag"
              ></span>
            </template>
          </a>
          <template v-for="(section, s) in sections">
            <a
              v-if="section.inMenu"
              :key="s"
              class="header__content-link"
              :class="{ active: currentSection.id == section.id }"
              href="#"
              @click.prevent="goTo(section.link ? section.link : section.id)"
            >
              {{ section.name }}
            </a>
          </template>
          <a
            v-if="isWalletConnected && saleStart"
            class="header__content-link"
            :href="linkCards.link"
            target="_blank"
          >
            {{ linkCards.name }}
          </a>
          <button
            v-if="!saleStart"
            class="button button--connect"
            :class="whitelistClass"
            @click="goTo('/whitelist')"
          >
            <span></span><span></span><span></span><span></span><span></span><span></span>
            Whitelist me
          </button>
          <!-- <button class="button button--mint button--yellow" @click="goTo('#mint')">
            <span></span><span></span><span></span><span></span><span></span><span></span>
            Mint
          </button> -->
          <button class="button button--connect button--yellow" @click="connectMetaMask">
            <span></span><span></span><span></span><span></span><span></span><span></span>
            {{ connectButtonText }}
          </button>
        </div>
      </div>

      <div v-else class="header__content">
        <router-link class="header__logo" to="/">
          <svg width="91" height="90" aria-hidden="true" :style="{ fill: logoColor }">
            <use xlink:href="#logo"></use>
          </svg>
        </router-link>

        <div class="header__social-links">
          <a
            v-for="(link, i) in social_links"
            :key="i"
            class="header__social-link"
            :href="link.href"
            target="_blank"
          >
            <svg width="50" height="50" aria-hidden="true">
              <use :xlink:href="link.icon"></use>
            </svg>
            <template v-if="link.tag">
              <span
                class="header__social-tag"
                :class="
                  currentSection.class === 'bg-pink'
                    ? 'header__social-tag--blue'
                    : 'header__social-tag--pink'
                "
                v-html="link.tag"
              ></span>
            </template>
          </a>
        </div>

        <button class="button button--toggle" :class="{ close: !menuOpened }" @click="menuToggle()">
          <img
            class="header__burger"
            :class="{ close: menuOpened }"
            src="@/assets/img/burger.svg"
            alt="burger menu"
          />
        </button>

        <transition name="slide-left">
          <div v-if="menuOpened" class="menu__content bg-pink">
            <div class="header__sections">
              <div class="header__content-links">
                <template v-for="(section, s) in sections">
                  <a
                    v-if="section.inMenu"
                    :key="s"
                    class="header__content-link"
                    href="#"
                    @click.prevent="goTo(section.link ? section.link : section.id)"
                  >
                    {{ section.name }}
                  </a>
                </template>
                <a
                  v-if="isWalletConnected && saleStart"
                  class="header__content-link"
                  :href="linkCards.link"
                  target="_blank"
                >
                  {{ linkCards.name }}
                </a>
              </div>
              <!-- <button class="button button--mint button--yellow" @click="goTo('#mint')">
                <span></span><span></span><span></span><span></span><span></span><span></span>
                Mint
              </button> -->
              <button class="button button--connect button--yellow" @click="connectMetaMask">
                <span></span><span></span><span></span><span></span><span></span><span></span>
                {{ connectButtonText }}
              </button>
            </div>
            <div class="header__copyright">
              <p>
                COPYRIGHT © 2022 <br />
                ALL RIGHTS RESERVED
              </p>
              <p><a href="mailto:survivors@m101.space">survivors@m101.space</a></p>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  components: {},
  props: {
    swiper: {
      type: Object,
      default: null
    },
    sections: {
      type: Array,
      default: null
    },
    currentSection: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      menuOpened: false,
      social_links: [
        {
          href: 'https://twitter.com/M101_Shelter',
          icon: '#twitter'
        },
        {
          href: 'http://discord.gg/m101',
          icon: '#discord'
        },
        {
          href: 'https://opensea.io/collection/m101survivors',
          icon: '#opensea',
          tag: 'S1'
        },
        {
          href: 'https://opensea.io/collection/m102expansion',
          icon: '#opensea',
          tag: 'S2'
        }
        // {
        //   href: 'https://www.instagram.com/m101nft/',
        //   icon: '#instagram'
        // }
      ],
      linkCards: {
        link: 'https://opensea.io/account/m102expansion',
        name: 'MY SURVIVORS'
      }
    }
  },
  computed: {
    isWalletConnected() {
      return this.$root.isWalletConnected
    },
    connectButtonText() {
      return this.isWalletConnected ? 'CONNECTED' : 'CONNECT WALLET'
    },
    logo() {
      return this.currentSection.class === 'bg-pink'
        ? '@/assets/img/logo.svg'
        : '@/assets/img/logo-white.svg'
    },
    logoColor() {
      return this.currentSection.class === 'bg-pink' ? '#020027' : '#ffffff'
    },
    whitelistClass() {
      return this.currentSection.class === 'bg-pink' ? 'button--yellow' : 'button--pink'
    },
    presaleStart() {
      return this.$root.presaleStart
    },
    presaleEnd() {
      return this.$root.presaleEnd
    },
    saleStart() {
      return this.$root.saleStart
    }
  },
  mounted() {
    window.addEventListener('resize', this.menuClose)
    window.addEventListener('scroll', this.menuClose)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.menuClose)
    window.removeEventListener('scroll', this.menuClose)
  },
  created() {
    document.addEventListener('keyup', evt => {
      if (evt.keyCode === 27) {
        this.menuClose()
      }
    })
  },
  methods: {
    connectMetaMask() {
      this.$root.connectMetaMask()
    },
    menuToggle() {
      this.menuOpened = !this.menuOpened
    },
    menuClose() {
      this.menuOpened = false
    },
    async goTo(link, offset) {
      if (link[0] === '/') {
        await this.$router.push({
          path: link
        })
        return
      }

      if (link === '#main' && this.$route.path === '/') {
        this.$scrollToTop()
        if (this.swiper && window.scrollY === 0) {
          this.swiper.slideTo(0)
        }
        return
      }

      if (!offset) {
        offset = this.$root.isMobile || this.$root.isTablet ? -20 : -100
        if (link === '#races') {
          offset = 10
        }
      }

      if (this.currentSection.id === '#main' && this.swiper) {
        this.swiper.slideTo(1)
        setTimeout(() => {
          this.$scrollTo(link, offset)
        }, 700)
      }

      if (!this.$scrollTo(link, offset)) {
        if (this.$route.path !== '/') {
          await this.$router.push({
            path: '/'
          })
          setTimeout(() => {
            this.$scrollTo(link, offset)
          }, 500)
        }
      }
    }
  }
}
</script>
