<template>
  <div id="races" class="section races bg-dark">
    <div class="races__bg-pattern"></div>
    <div class="container">
      <div class="races__content">
        <div class="races__texts">
          <h2 class="story__title">{{ title }}</h2>
          <template v-if="!$root.isMobile && !$root.isTablet">
            <vue-typed-js
              :strings="[text_top]"
              :type-speed="15"
              :show-cursor="false"
              :style="'min-height: 4rem; align-items: flex-start;'"
            >
              <div class="typing"></div>
            </vue-typed-js>
          </template>
          <template v-else>
            <div v-html="text_top"></div>
          </template>
          <div ref="scrolled" class="races__info">
            <div v-for="(race, r) in races" :key="r" class="races__info-wrap">
              <img
                :src="!$root.isTablet ? race.img : race.img_mob"
                alt="race"
                class="races__info-img"
              />
              <!-- <div v-if="$root.isMobile || $root.isTablet" class="races__info-text">
                {{ race.text }}
              </div> -->
            </div>
          </div>
          <template v-if="!$root.isMobile && !$root.isTablet">
            <!-- <template v-for="(race, r) in races">
              <div v-if="race.active" :key="r" class="races__info-text">
                {{ race.text }}
              </div>
            </template> -->
          </template>
          <template v-if="!$root.isMobile && !$root.isTablet">
            <vue-typed-js
              :strings="[text_bottom]"
              :style="'min-height: 4rem; align-items: flex-start; margin-top: 0;transform: translateY(-70px);'"
              :type-speed="15"
              :start-delay="8000"
              :show-cursor="false"
            >
              <div class="typing"></div>
            </vue-typed-js>
          </template>
          <template v-else>
            <div
              :style="
                !$root.isMobile
                  ? 'min-height: 4rem; align-items: flex-start; margin-top: 0;transform: translateY(-70px);'
                  : 'min-height: 4rem; align-items: flex-start; margin-top: 0'
              "
              v-html="text_bottom"
            ></div>
          </template>
        </div>
      </div>
      <div class="races__panel">
        <span></span>
        <span></span>
        <p>
          WANT TO KNOW THE RARITY LEVEL OF YOUR M101? CHECK OUT
          <router-link to="/ranking"> RANKING</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Races',
  props: {},
  data() {
    return {
      title: 'Races & Rarity',
      text_top:
        '<p>The m101 Shelter Metaverse is presented with 6 races in the current NFT collection. More are still unknown and will be added in the free Collection for m101 holders according to the Roadmap activation.</p>',
      text_bottom:
        '<p>M101 Shelter Survivors have more than 400 hand-drawn attributes: some were created for a specific race or gender with hundreds of color variations. Some M101 Survivors are considered Legendary and are fully hand-drawn and presented with exclusive 1x1 traits.</p>',
      races: [
        {
          img: require('@/assets/img/xol.png'),
          img_mob: require('@/assets/img/xol-mob.png')
        },
        {
          img: require('@/assets/img/human.png'),
          img_mob: require('@/assets/img/human-mob.png')
        },
        {
          img: require('@/assets/img/ract.png'),
          img_mob: require('@/assets/img/ract-mob.png')
        },
        {
          img: require('@/assets/img/necris.png'),
          img_mob: require('@/assets/img/necris-mob.png')
        },
        {
          img: require('@/assets/img/volardis.png'),
          img_mob: require('@/assets/img/volardis-mob.png')
        },
        {
          img: require('@/assets/img/gen.png'),
          img_mob: require('@/assets/img/gen-mob.png')
        },
        {
          img: require('@/assets/img/ukktor.png'),
          img_mob: require('@/assets/img/ukktor-mob.png')
        },
        {
          img: require('@/assets/img/tiegod.png'),
          img_mob: require('@/assets/img/tiegod-mob.png')
        },
        {
          img: require('@/assets/img/prior.png'),
          img_mob: require('@/assets/img/prior-mob.png')
        }
      ]
    }
  },
  watch: {},
  mounted() {}
}
</script>
