<template>
  <div class="footer bg-dark">
    <div class="container">
      <template v-if="!$root.isMobile && !$root.isTablet">
        <div class="footer__top">
          <FooterSubscribe />

          <div class="footer__social-links">
            <a
              v-for="(link, i) in social_links"
              :key="i"
              class="footer__social-link"
              :href="link.href"
              target="_blank"
            >
              <svg width="45" height="45" aria-hidden="true">
                <use :xlink:href="link.icon"></use>
              </svg>
              <template v-if="link.tag">
                <span class="footer__social-tag" v-html="link.tag"></span>
              </template>
            </a>
          </div>
        </div>
        <div class="footer__copyright">
          <div class="footer__copyright-left">
            <div>
              <span> S2 VERIFIED SMART CONTRACT: </span>
              <a
                href="https://etherscan.io/address/0xb6aa22bbb40ff145fd27525107ed7afe583ee5c4"
                target="_blank"
              >
                0xb6aa22bbb40ff145fd27525107ed7afe583ee5c4
              </a>
            </div>
            <span>{{ copyright.text }}</span>
          </div>
          <a :href="'mailto:' + copyright.email">{{ copyright.email }}</a>
        </div>
      </template>
      <template v-else>
        <div
          v-if="$root.isMobile && !['Spacenomics', 'Whitelist'].includes(this.$route.name)"
          class="footer__rocket"
        >
          <img src="@/assets/img/rocket.png" alt="rocket" />
        </div>

        <FooterSubscribe />

        <div class="footer__social-links">
          <a
            v-for="(link, i) in social_links"
            :key="i"
            class="footer__social-link"
            :href="link.href"
            target="_blank"
          >
            <svg width="45" height="45" aria-hidden="true">
              <use :xlink:href="link.icon"></use>
            </svg>
            <template v-if="link.tag">
              <span class="footer__social-tag" v-html="link.tag"></span>
            </template>
          </a>
        </div>
        <div class="footer__copyright">
          <p>
            COPYRIGHT © 2022 <br />
            ALL RIGHTS RESERVED
          </p>
          <p><a href="mailto:survivors@m101.space">survivors@m101.space</a></p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FooterSubscribe from './FooterSubscribe.vue'

export default {
  name: 'Footer',
  components: { FooterSubscribe },
  props: {
    swiper: {
      type: Object,
      default: null
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    sectionsLength: {
      type: Number,
      default: 0
    },
    prevSection: {
      type: Object,
      default: null
    },
    currentSection: {
      type: Object,
      default: null
    },
    nextSection: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      social_links: [
        {
          href: 'https://twitter.com/M101_Shelter',
          icon: '#twitter'
        },
        {
          href: 'http://discord.gg/m101',
          icon: '#discord'
        },
        {
          href: 'https://opensea.io/collection/m101survivors',
          icon: '#opensea',
          tag: 'S1'
        },
        {
          href: 'https://opensea.io/collection/m102expansion',
          icon: '#opensea',
          tag: 'S2'
        }
        // {
        //   href: 'https://www.instagram.com/m101nft/',
        //   icon: '#instagram'
        // }
      ],
      copyright: {
        text: 'copyright © 2022 all rights reserved',
        email: 'survivors@m101.space'
      }
    }
  },
  computed: {
    classBg() {
      return this.$root.isMobile
        ? ['Roadmap', 'Spacenomics', 'Starpoints', 'Ranking', 'Voting', 'Whitelist'].includes(
            this.$route.name
          )
          ? this.currentSection.class
          : ''
        : 'bg-dark'
    }
  }
}
</script>
