<template>
  <div id="ranking" class="section ranking bg-dark">
    <div class="container">
      <h2 class="ranking__title">RARITY</h2>
      <div class="ranking__text ranking__text--white">
        You can use our own ranking system by putting a token`s ID or # Rarity Rank in the search
        field OR visit M101 Shelter page on rarity tools
        <a href="https://rarity.tools/m101survivors/" target="_blank">website</a>. <br />
        Please keep in mind that their data update takes more time and may differ.
      </div>
      <div v-if="textError.length" class="ranking__text">{{ textError }}</div>
      <div class="ranking__search-wrap">
        <div class="ranking__search-item">
          <div class="ranking__text">Search by ID</div>
          <form
            class="ranking__search"
            :class="{ error: searchError }"
            @submit.prevent="getToken()"
          >
            <input v-model="token_id" type="number" :placeholder="placeholder" />
            <button class="button button--search button--yellow" @click.prevent="getToken()">
              <span></span><span></span><span></span><span></span><span></span><span></span>
              Find
              <svg>
                <use xlink:href="#zoom"></use>
              </svg>
            </button>
          </form>
        </div>
        <div class="ranking__search-item">
          <div class="ranking__text">Search by Rank Number</div>
          <form
            class="ranking__search"
            :class="{ error: searchErrorRank }"
            @submit.prevent="getTokenByRank()"
          >
            <input v-model="rank" type="number" :placeholder="placeholderRank" />
            <button class="button button--search button--yellow" @click.prevent="getTokenByRank()">
              <span></span><span></span><span></span><span></span><span></span><span></span>
              Find
              <svg>
                <use xlink:href="#zoom"></use>
              </svg>
            </button>
          </form>
        </div>
      </div>
      <div v-if="computedToken" class="ranking__wrap" :class="{ overlay: overlay }">
        <div class="ranking__token">
          <div class="ranking__token-info">
            <span>Token</span>
            <span>{{ computedToken.token_id }}</span>
          </div>
          <div class="ranking__token-info">
            <span>Score</span>
            <span v-if="computedToken.score">{{ parseFloat(computedToken.score).toFixed(3) }}</span>
          </div>
          <div class="ranking__token-info">
            <span>Rank</span>
            <span v-if="computedToken.rank">{{ computedToken.rank }}/3101</span>
          </div>
          <div class="ranking__token-image">
            <img v-if="computedToken.image" :src="computedToken.image" alt="token" />
          </div>
          <a
            :href="computedToken.os_link"
            class="button button--opensea button--yellow"
            target="_blank"
          >
            <span></span><span></span><span></span><span></span><span></span><span></span>
            <svg>
              <use xlink:href="#opensea"></use>
            </svg>
            View on Opensea
          </a>
        </div>
        <div class="ranking__traits">
          <template v-for="(trait, t) in computedToken.traits">
            <div v-if="trait.value !== 'none'" :key="t" class="trait">
              <div class="trait__name">{{ trait.type }}</div>
              <div class="trait__value-wrap">
                <div class="trait__value">
                  <span>{{ trait.value }}</span>
                  <span>{{ trait.count }}/3101</span>
                </div>
                <div class="trait__percent">{{ parseFloat(trait.rare).toFixed(3) }}%</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Ranking',
  props: {},
  data() {
    return {
      token: null,
      token_id: 1,
      rank: '',
      searchError: false,
      searchErrorRank: false,
      overlay: true,
      placeholder: 'Search by ID',
      placeholderRank: 'Search by Rank',
      textError: ''
    }
  },
  computed: {
    computedToken() {
      const token = this.token ? this.token : this.$root.tokenDef

      if (!token.traits) {
        return token
      }

      let count = null
      token.traits.forEach((trait, i) => {
        if (trait.type === 'Trait Count') {
          count = trait
          token.traits.splice(i, 1)
        }
      })

      if (count) {
        token.traits.push(count)
      }

      return token
    }
  },
  created() {
    setTimeout(() => {
      this.overlay = false
    }, 500)
  },
  mounted() {},
  methods: {
    setError(message = false, rank = false) {
      if (rank) {
        this.searchErrorRank = false
      } else {
        this.searchError = true
      }
      if (message) {
        this.textError = "Try again later! The token you're trying to check isn`t minted yet!"
      }
      setTimeout(() => {
        this.searchError = false
        this.searchErrorRank = false
        this.textError = ''
      }, 3000)
    },
    async getTokenByRank() {
      if (!this.rank.length && this.rank !== 0) {
        this.setError(false, true)
        return
      }
      this.overlay = true

      const response = await axios
        .get(this.$root.apiUrl + '/token/rank/' + this.rank)
        .catch(error => {
          console.log(error)
        })

      if (response) {
        const data = response.data
        if (!data.token_id && data.token_id !== 0) {
          this.setError(true, true)
        } else {
          if (this.token) {
            this.token.image = ''
          }
          setTimeout(() => {
            this.token = data
            this.token_id = data.token_id
          }, 50)
        }
      }

      setTimeout(() => {
        this.overlay = false
      }, 100)
    },
    async getToken() {
      if (!this.token_id.length && this.token_id !== 0 && this.token_id !== 1) {
        this.setError()
        return
      }
      this.overlay = true

      const response = await axios
        .get(this.$root.apiUrl + '/token/' + this.token_id)
        .catch(error => {
          console.log(error)
        })

      if (response) {
        const data = response.data
        if (!data.token_id && data.token_id !== 0) {
          this.setError(true)
        } else {
          if (this.token) {
            this.token.image = ''
          }
          setTimeout(() => {
            this.token = data
            this.rank = data.rank
          }, 50)
        }
      }

      setTimeout(() => {
        this.overlay = false
      }, 100)
    }
  }
}
</script>
