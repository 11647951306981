<template>
  <div id="whitelist" class="section whitelist bg-dark">
    <div class="container">
      <div ref="scrolled" class="whitelist__inner">
        <h2 class="whitelist__title">Whitelist</h2>
        <div class="whitelist__panel-wrap">
          <div class="whitelist__panel">
            <span></span>
            <span></span>
            <h2>SEASON 2 PRE-SALE ON MARCH 13!</h2>
            <p>
              PLEASE CHOOSE 1 OUT OF 8 OPTIONS TO GET A WHITELIST SPOT. IT WILL ALLOW YOU TO MINT
              MAX 3 NFTS PER 1 ETH ADDRESS ON PRE-SALE.
            </p>
            <p>SPECIAL PRE-SALE PRICE WITH <i>25% OFF</i> IS ONLY <i>0.02 ETH</i>!</p>
            <p>
              <i>FYI:</i> If you’re eligible for several options - you can get another WL spot by
              providing a new ETH address.
            </p>
          </div>
          <div class="whitelist__panel whitelist__panel--form">
            <span></span>
            <span></span>
            <h3>SPECIAL REWARD!</h3>
            <p>
              IF YOU`RE CLAIMING WL SPOT BY HOLDING 3 ALL-STAR<br />NFTS + 1 M101 SURVIVOR<br />YOU`RE
              RECEIVING SPECIAL GAS-FREE NFT
            </p>
          </div>
          <div v-if="$root.isTablet" class="whitelist__pass">
            <div class="whitelist__pass-text">STAR PASS!</div>
            <img src="@/assets/img/pass.gif" alt="star pass" />
          </div>
        </div>

        <div class="whitelist__form-wrapper">
          <section class="whitelist__form">
            <h2 class="whitelist__form-title">Please fill in the FORM</h2>
            <fieldset class="whitelist__fieldset">
              <div v-for="(input, i) in inputs" :key="i" class="whitelist__radio">
                <label
                  :for="input.id"
                  class="whitelist__label"
                  :class="{ 'whitelist__label--checked': input.selected }"
                  @click.prevent="toggleRadio(i)"
                >
                  <input :id="input.id" type="radio" :value="input.id" />
                  <p v-html="input.name"></p>
                </label>
              </div>
            </fieldset>
            <div class="address">
              <div class="address__text">ETH Address</div>
              <div class="address__input" @click="сheckAddressInput()">
                <input
                  readonly
                  disabled
                  type="text"
                  :placeholder="currentWallet"
                  :value="isSuccess ? currentWallet : ''"
                />

                <template v-if="isSuccess">
                  <svg width="16" height="13" aria-hidden="true">
                    <use xlink:href="#tick-yellow"></use>
                  </svg>
                </template>
              </div>
              <small v-if="isFailed" class="address__error">
                Please connect your metamask wallet
              </small>
              <template v-if="selected.socials">
                <template v-for="(social, s) in selected.socials">
                  <div :key="s">
                    <div class="address__text">
                      <span>{{ social }}</span>
                    </div>
                    <div class="address__input" :class="{ error: socialError }">
                      <input
                        v-model="socials[social].value"
                        :type="socials[social].type"
                        :placeholder="socials[social].placeholder"
                      />
                    </div>
                    <small v-if="socials[social].text">{{ socials[social].text }}</small>
                  </div>
                </template>
              </template>
              <p class="address__notification">
                *Make sure the information you provided is correct, otherwise WL spot won`t be
                granted.
              </p>
              <button
                class="button button--send button--yellow"
                :class="{ overlay: inProcess }"
                @click="submit()"
              >
                <span></span><span></span><span></span><span></span><span></span><span></span>
                Send
              </button>
            </div>
          </section>

          <div class="whitelist__panels-wrapper">
            <div v-if="!$root.isTablet" class="whitelist__pass">
              <div class="whitelist__pass-text">STAR PASS!</div>
              <img src="@/assets/img/pass.gif" alt="star pass" />
            </div>
            <Frame v-if="!$root.isTablet" :status="statusText" />
          </div>
        </div>
        <Frame v-if="$root.isTablet" :status="statusText" />

        <Details />
      </div>
    </div>
  </div>
</template>

<script>
import Frame from '@/components/whitelist/Frame'
import Details from '@/components/whitelist/Details'

import axios from 'axios'
import Web3 from 'web3'
import { contractAddress, abi } from '@/utils/shelter.js'
import { contractAddressAllStars, abiAllStars } from '@/utils/allstars.js'

const infura = 'https://mainnet.infura.io/v3/4a91eb50e36d430681e0000756d6261b'

const statuses = ['pending', 'success', 'failed', 'banned']

export default {
  name: 'Whitelist',
  components: { Details, Frame },
  props: {},
  data() {
    return {
      inputs: [
        {
          id: 7,
          name: 'SUBSCRIBE TO OUR NEWSLETTER',
          selected: true,
          socials: ['email']
        },
        {
          id: 0,
          name: 'Diamond Hands: holding 3 or more M101 NFTs of Season 1',
          selected: false,
          socials: ['email']
        },
        {
          id: 1,
          name: 'Hold 3 different <a href="https://opensea.io/collection/101allstar" target="_blank">All-Star</a> NFTs + 1 <a href="https://opensea.io/collection/m101survivors" target="_blank">M101 Survivor</a> - receive special STAR PASS!',
          selected: false,
          socials: []
        },
        {
          id: 2,
          name: 'Won in a Giveaway or other activity, i.e. contest, game etc. (Discord, Twitter, <a href="https://www.instagram.com/m101nft/" target="_blank">Instagram</a>) ',
          selected: false,
          socials: ['twitter', 'instagram', 'discord']
        },
        {
          id: 3,
          name: 'Joined 10 Twitter Raids + made 10 retweets of <a href="https://twitter.com/M101_Shelter" target="_blank">M101 official account</a>',
          selected: false,
          socials: ['twitter']
        },
        {
          id: 4,
          name: 'Got invited 10 people to server + got rank of Commander or higher on DISCORD',
          selected: false,
          socials: ['discord']
        },
        {
          id: 5,
          name: 'Changed username to JohnDoe| M101#1234 + got rank of General DISCORD',
          selected: false,
          socials: ['discord']
        },
        {
          id: 6,
          name: 'Got rank of Lord Admiral on our DISCORD',
          selected: false,
          socials: ['discord']
        }
      ],
      socials: {
        twitter: {
          placeholder: '@Username',
          type: 'text',
          value: ''
        },
        instagram: {
          placeholder: 'Username',
          type: 'text',
          value: ''
        },
        discord: {
          placeholder: 'Username#1234',
          type: 'text',
          value: ''
        },
        email: {
          placeholder: 'example@example.com',
          type: 'email',
          value: '',
          text: '*As soon as your WL spot is approved you will receive a confirmation on your email address'
        }
      },
      isFailed: false,
      web3: new Web3(Web3.givenProvider || infura),
      shelterContract: null,
      allStarsContract: null,
      balance: 0,
      balanceAllStars: [],
      allStarsTokens: [1, 2, 3, 4, 5, 6, 7],
      inProcess: false,
      status: null,
      socialError: false,
      ready: false
    }
  },
  computed: {
    currentWallet() {
      return this.$root.currentWallet
    },
    isWalletConnected() {
      return this.$root.isWalletConnected
    },
    isSuccess() {
      return this.statusText === 'success'
    },
    statusText() {
      return statuses[this.status]
    },
    canSubmit() {
      return !this.statusText || this.statusText === 'failed'
    },
    countAllStars() {
      if (!this.balanceAllStars) {
        return 0
      }
      let count = 0
      this.balanceAllStars.forEach(balance => {
        if (balance > 0) {
          count++
        }
      })
      return count
    },
    selected() {
      return this.inputs.filter(input => {
        return input.selected
      })[0]
    }
  },
  watch: {
    async currentWallet(newV, oldV) {
      if (this.ready) {
        await this.web3Handler()
        await this.getStatus()
      }
    }
  },
  async mounted() {
    setTimeout(async () => {
      if (this.connectCheck()) {
        await this.web3Handler()
        if (!this.status) {
          await this.getStatus()
        }
        this.ready = true
      } else {
        this.ready = true
      }
    }, 1000)
  },
  methods: {
    async web3Handler() {
      this.shelterContract = new this.web3.eth.Contract(abi, contractAddress)
      this.allstarsContract = new this.web3.eth.Contract(abiAllStars, contractAddressAllStars)

      const { shelterContract, allstarsContract, currentWallet, allStarsTokens } = this

      const allStarsWallets = []
      for (let index = 0; index < allStarsTokens.length; index++) {
        allStarsWallets.push(currentWallet)
      }

      const response = await Promise.all([
        currentWallet ? shelterContract.methods.balanceOf(currentWallet).call() : 0,
        currentWallet
          ? await allstarsContract.methods.balanceOfBatch(allStarsWallets, allStarsTokens).call()
          : 0
      ]).catch(error => {
        console.log(error)
        setTimeout(() => {
          this.web3Handler()
        }, 1000)
      })

      const [balance, balanceAllStars] = response

      this.balance = Number(balance)
      this.balanceAllStars = balanceAllStars

      return Promise.resolve()
    },
    connectCheck() {
      if (!this.isWalletConnected) {
        this.$notify({
          group: 'check-notifications',
          type: 'error',
          text: 'Please connect your Metamask wallet.'
        })

        this.$root.connectMetaMask()

        return false
      }

      return true
    },
    сheckAddressInput() {
      if (!this.connectCheck()) {
        this.isFailed = true
        setTimeout(() => {
          this.isFailed = false
        }, 4000)
      }
    },
    submitCheck() {
      this.сheckAddressInput()
      if (!this.connectCheck()) {
        return false
      }

      if (!this.canSubmit) {
        this.$notify({
          group: 'check-notifications',
          type: 'error',
          text: 'Your request was already sent.'
        })
        return false
      }

      if (this.selected.id === 0 && this.balance < 3) {
        this.$notify({
          group: 'check-notifications',
          type: 'error',
          text: 'You don`t have enough M101 Tokens.'
        })
        return false
      }

      if (this.selected.id === 1 && (this.balance < 1 || this.countAllStars < 3)) {
        this.$notify({
          group: 'check-notifications',
          type: 'error',
          text: 'You don`t have enough All Star or M101 tokens.'
        })
        return false
      }

      if (this.selected.socials) {
        let valid = false
        for (const social of this.selected.socials) {
          if (this.socials[social].value.length > 2) {
            valid = true
          }
        }
        if (!valid) {
          this.socialError = true
          this.$notify({
            group: 'check-notifications',
            type: 'error',
            text: 'Please fill at least one field.'
          })
          setTimeout(() => {
            this.socialError = false
          }, 3000)
          return false
        }
      }

      return true
    },
    async submit() {
      await this.web3Handler()

      if (!this.status) {
        await this.getStatus()
      }

      if (!this.submitCheck() || this.inProcess) {
        return false
      }

      await this.$root.loginWeb3()

      this.inProcess = true

      const data = new FormData()
      data.append('address', this.currentWallet)
      data.append('type', Number(this.selected.id))

      let content = ''
      for (const social in this.socials) {
        if (this.socials[social].value.length > 2) {
          content += '<b>' + social + ':</b> ' + this.socials[social].value + '<br>'
        }
      }
      data.append('content', content)

      const response = await axios
        .post(this.$root.apiUrl + '/whitelist/request', data, this.$root.headers)
        .catch(error => {
          console.log(error)
        })

      if (response.data.success) {
        this.status = response.data.status

        try {
          /* eslint-disable-next-line */
          FbEvents.InitiateCheckout([
            {
              id: this.selected.id,
              quantity: 1,
              item_price: 0
            }
          ])
        } catch (error) {
          console.log(error)
        }
      }

      this.inProcess = false
    },

    async getStatus() {
      this.status = null

      await this.$root.loginWeb3()

      const response = await axios
        .get(this.$root.apiUrl + '/whitelist/status', this.$root.headers)
        .catch(error => {
          console.log(error)
          return error.response
        })

      if (response.data.address) {
        this.inputs.forEach((input, index) => {
          if (input.id === response.data.type) {
            this.toggleRadio(index)
          }
        })
        this.status = response.data.status
      }

      return Promise.resolve()
    },
    toggleRadio(i) {
      if (!this.canSubmit) {
        return
      }
      this.connectCheck()
      this.$toggleArray(i, this.inputs, 'selected')
      this.clearSocials()
    },
    clearSocials() {
      for (const social in this.socials) {
        this.socials[social].value = ''
      }
    }
  }
}
</script>
