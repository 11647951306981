<template>
  <div class="merch bg-dark">
    <div class="container">
      <div class="welcome">
        <div class="welcome__inner">
          <div class="welcome__img-wrapper">
            <div class="welcome__img">
              <img src="@/assets/img/cabin.png" alt="M101 Cabins Collection" />
            </div>
          </div>

          <div class="welcome__texts">
            <p class="welcome__title">M101 Cabins Collection</p>
            <p class="welcome__text">
              Are you excited for the M101 Metaverse? You definitely need your own unique Cabin on
              Starship!
            </p>
            <p class="welcome__text">
              Become a SEASON 2 holder and reserve your
              <template v-if="!saleStart">
                <router-link to="/whitelist">whitelist spot</router-link>
              </template>
              <template v-else> whitelist spot </template>
              now: get a priority on getting Cabins ahead of the collection pre-sale announcement.
            </p>
            <p class="welcome__text">
              What use of Cabins in Metaverse? Just ask - and we will build a voxel model for you in
              the Sandbox.
            </p>
          </div>
        </div>
        <!-- <div class="merch__block">
          <div class="merch__img-wrapper">
            <div class="merch__img">
              <video v-if="!$root.isMobile" autoplay loop muted>
                <source src="@/assets/img/merch.webm" type="video/webm" />
              </video>
              <img v-else src="@/assets/img/merch.png" alt="Shelter Merch" />
            </div>
          </div>
          <div class="merch__texts">
            <p class="merch__title">M101 MetaMerch</p>
            <p class="merch__text">
              Exclusively as a SEASON 2 holder you can personalize
              <em>your Avatar in the Sandbox</em> or any other metaverse (yes, it will be multi
              integrated!) by minting for free a piece of merch with your M101 Survivor!
            </p>
          </div>
        </div> -->
      </div>
      <div v-if="!saleStart" class="merch__panel">
        <span></span>
        <span></span>
        <div class="merch__panel-text">
          <router-link to="/whitelist">CLICK HERE TO GET YOUR WHITELIST </router-link>SPOT ASAP!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Allstars',
  components: {},
  data() {
    return {}
  },
  computed: {
    presaleStart() {
      return this.$root.presaleStart
    },
    presaleEnd() {
      return this.$root.presaleEnd
    },
    saleStart() {
      return this.$root.saleStart
    }
  },
  methods: {}
}
</script>
