<template>
  <section class="voting__blocks">
    <fieldset class="voting__fieldset">
      <div v-for="(input, i) in inputs" :key="i" class="voting__radio">
        <label
          :for="input.id"
          class="voting__label"
          :class="{ 'voting__label--checked': input.selected }"
          @click="toggleRadio(i)"
        >
          <input
            :id="'input_' + input.id"
            type="radio"
            :value="input.id"
            :disabled="input.disabled"
          />
          <span>{{ input.name }}</span>
        </label>
      </div>
    </fieldset>
    <div class="survey">
      <transition name="fade">
        <div v-if="showActive" class="survey__items">
          <h2>
            <span>{{ activeTitle }} </span>
          </h2>
          <div
            v-for="(question, i) in questionsActive"
            :key="i"
            class="survey__item survey__item--opened"
          >
            <span class="survey__item-border-top"></span>
            <span class="survey__item-border-bottom"></span>
            <span class="survey__item-border-left"></span>
            <span class="survey__item-border-right"></span>
            <div class="survey__item-info">
              <span class="survey__item-status">Active</span>
              <template v-if="question.days_left">
                <span class="survey__days-left"> {{ formatDays(question.days_left) }}</span>
              </template>
            </div>

            <p class="survey__item-name">{{ question.title }}</p>

            <div class="survey__texts" v-html="question.short_content"></div>
            <template v-if="isVoted(question.votes)">
              <div class="survey__results">
                <p class="survey__result survey__result--warp">
                  <span>Warp:</span>
                  <span class="survey__value survey__value--warp">{{ question.votes_all }}</span>
                </p>
                <span class="survey__divider"></span>
                <p class="survey__result">
                  <span>Yes:</span>
                  <span class="survey__value survey__value--yes">{{ question.votes_for }}</span>
                </p>
                <span class="survey__divider"></span>
                <p class="survey__result">
                  <span>No:</span>
                  <span class="survey__value survey__value--no"> {{ question.votes_against }}</span>
                </p>
              </div>
            </template>
            <template v-else>
              <p class="survey__question">Do you support this decision?</p>
              <div class="survey__buttons">
                <button
                  class="button button--yellow"
                  :class="{ overlay: inProcess }"
                  @click.prevent="vote(question, 1)"
                >
                  <span></span><span></span><span></span><span></span><span></span><span></span>Yes
                </button>
                <button
                  class="button button--no"
                  :class="{ overlay: inProcess }"
                  @click.prevent="vote(question, 0)"
                >
                  <span></span><span></span><span></span><span></span><span></span><span></span>No
                </button>
              </div>
            </template>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-if="showClosed" class="survey__items">
          <h2>
            <span>{{ closedTitle }} </span>
          </h2>
          <div
            v-for="(question, q) in questionsClosed"
            :key="q"
            class="survey__item survey__item--closed"
          >
            <div class="survey__item-info">
              <span class="survey__item-status">{{ question.closed_status }}</span>
              <!-- <template v-if="question.decision === 'yes'">
                <svg width="32" height="23">
                  <use xlink:href="#ok"></use>
                </svg>
              </template>
              <template v-else>
                <svg width="28" height="28">
                  <use xlink:href="#fail"></use>
                </svg>
              </template> -->
            </div>
            <p class="survey__item-name">{{ question.title }}</p>
            <div class="survey__texts" v-html="question.short_content"></div>
            <div class="survey__results">
              <p class="survey__result survey__result--warp">
                <span>Warp:</span>
                <span class="survey__value survey__value--warp">{{ question.votes_all }}</span>
              </p>
              <span class="survey__divider"></span>
              <p class="survey__result">
                <span>Yes:</span>
                <span class="survey__value survey__value--yes">{{ question.votes_for }}</span>
              </p>
              <span class="survey__divider"></span>
              <p class="survey__result">
                <span>No:</span>
                <span class="survey__value survey__value--no"> {{ question.votes_against }}</span>
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import Web3 from 'web3'
import { contractAddress, abi, contractAddressS2, abiS2 } from '@/utils/shelter.js'

const infura = 'https://mainnet.infura.io/v3/4a91eb50e36d430681e0000756d6261b'

export default {
  name: 'VotingBlocks',
  props: {},
  data() {
    return {
      questionsActive: [],
      questionsClosed: [],
      activeTitle: 'Active',
      closedTitle: 'Closed',
      inputs: [
        {
          id: 0,
          name: 'All',
          selected: true,
          disabled: false
        },
        {
          id: 1,
          name: 'Active',
          selected: false,
          disabled: false
        },

        {
          id: 2,
          name: 'Closed',
          selected: false,
          disabled: false
        }
      ],
      questions: [],
      web3: new Web3(Web3.givenProvider || infura),
      shelterContract: null,
      shelterContractS2: null,
      balance: 0,
      inProcess: false
    }
  },
  computed: {
    currentWallet() {
      return this.$root.currentWallet
    },
    isWalletConnected() {
      return this.$root.isWalletConnected
    },
    showActive() {
      return (this.inputs[0].selected || this.inputs[1].selected) && this.questionsActive.length
    },
    showClosed() {
      return (this.inputs[0].selected || this.inputs[2].selected) && this.questionsClosed.length
    },
    canVote() {
      return this.balance > 0
    },
    voteScores() {
      return this.balance > 0 ? this.balance + 1 : 0
    }
  },
  watch: {
    currentWallet(newV, oldV) {
      this.web3Handler()
    }
  },
  async mounted() {
    setTimeout(async () => {
      await this.getQuestions()
      await this.web3Handler()
      this.connectCheck()
    }, 1000)
  },
  methods: {
    formatDays(days) {
      const day = days === 1 ? 'day' : 'days'

      return days + ' ' + day + ' left'
    },
    async web3Handler() {
      this.shelterContract = await new this.web3.eth.Contract(abi, contractAddress)
      this.shelterContractS2 = await new this.web3.eth.Contract(abiS2, contractAddressS2)

      const { shelterContract, shelterContractS2, currentWallet } = this

      const response = await Promise.all([
        currentWallet ? shelterContract.methods.balanceOf(currentWallet).call() : 0,
        currentWallet ? shelterContractS2.methods.balanceOf(currentWallet).call() : 0
      ]).catch(error => {
        console.log(error)
        setTimeout(() => {
          this.web3Handler()
        }, 1000)
      })

      const [balance, balanceS2] = response

      this.balance = Number(balance) + Number(balanceS2)

      return Promise.resolve()
    },
    connectCheck() {
      if (!this.isWalletConnected) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'Please connect your Metamask wallet.'
        })
        return false
      }

      return true
    },
    async readyToVote() {
      if (!this.connectCheck()) {
        return Promise.resolve(false)
      }

      if (!this.canVote) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'You don`t have any shelter tokens.'
        })
        return Promise.resolve(false)
      }

      return Promise.resolve(true)
    },
    async vote(question, vote) {
      await this.web3Handler()

      if (!(await this.readyToVote()) || this.inProcess) {
        return false
      }

      this.inProcess = true

      await this.$root.loginWeb3()

      const data = new FormData()
      data.append('address', this.currentWallet)
      data.append('id', question.id)
      data.append('vote', vote)
      data.append('scores', this.voteScores)

      const response = await axios
        .post(this.$root.apiUrl + '/vote', data, this.$root.headers)
        .catch(error => {
          console.log(error)
        })

      if (response.data.success) {
        this.$notify({
          group: 'app-notifications',
          type: 'success',
          text: 'Thanks for your vote!'
        })

        this.getQuestions()
      }

      this.inProcess = false
    },
    isVoted(votes) {
      return votes.filter(vote => {
        return vote.address === this.currentWallet
      })[0]
    },
    toggleRadio(i) {
      this.$toggleArray(i, this.inputs, 'selected')
    },
    async getQuestions() {
      const response = await axios.get(this.$root.apiUrl + '/questions').catch(error => {
        console.log(error)
      })

      this.questions = response.data

      this.questionsActive = this.questions.filter(question => {
        return !question.is_closed
      })
      this.questionsClosed = this.questions.filter(question => {
        return question.is_closed
      })

      return Promise.resolve()
    }
  }
}
</script>
