<template>
  <div id="roadmap" class="section roadmap bg-dark">
    <div class="container">
      <h2 class="roadmap__title">M101 METAVERSE ROADMAP</h2>
    </div>
    <div class="container">
      <div class="roadmap__content">
        <div class="roadmap__scheme">
          <img
            v-if="!$root.isTablet"
            src="@/assets/img/roadmap-scheme.png"
            alt="roadmap scheme"
            class="roadmap__image"
          />
          <img
            v-else-if="$root.isTablet && !$root.isMobile"
            src="@/assets/img/roadmap-scheme-tablet.png"
            alt="roadmap scheme"
            class="roadmap__image"
          />
          <img
            v-else-if="$root.isMobile"
            src="@/assets/img/roadmap-scheme-mobile.png"
            alt="roadmap scheme"
            class="roadmap__image roadmap__image--mobile"
          />
          <div class="scheme">
            <div class="scheme_wrapper">
              <div
                v-for="(item, i) in items"
                :key="i"
                class="scheme__item"
                @mouseover="toggleItems(i)"
                @mouseleave="item.active = false"
              >
                <h4 class="scheme__title button button--white">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span v-if="item.title !== 'SEASON 2'" class="name" v-html="item.title"></span>
                  <span v-if="item.title !== 'SEASON 2'" class="text" v-html="item.text"></span>
                  <router-link
                    v-else
                    class="scheme__link"
                    to="/season-2"
                    v-html="item.text"
                  ></router-link>
                </h4>
                <img
                  v-if="i === 0"
                  src="@/assets/img/arrow-hover.png"
                  alt="arrow"
                  class="scheme__arrow-hover"
                  :style="arrowStyle"
                />
                <transition v-if="!$root.isTablet" name="fade">
                  <ul v-show="item.active" class="scheme__list">
                    <template v-if="item.list">
                      <li v-for="(point, p) in item.list" :key="p" class="scheme__list-item">
                        <div class="scheme__list-wrapper">
                          <hr class="scheme__list-line scheme__list-line--before" />
                          <div class="scheme__list-text" v-html="point"></div>
                          <hr class="scheme__list-line scheme__list-line--after" />
                        </div>
                      </li>
                    </template>
                  </ul>
                </transition>
                <ul v-else class="scheme__list">
                  <template v-if="item.list">
                    <li v-for="(point, p) in item.list" :key="p" class="scheme__list-item">
                      <div class="scheme__list-wrapper">
                        <hr class="scheme__list-line scheme__list-line--before" />
                        <div class="scheme__list-text" v-html="point"></div>
                        <hr class="scheme__list-line scheme__list-line--after" />
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="roadmap__banner">
        <div class="roadmap__banner-wrapper">
          <p>Visit <router-link to="/spacenomics">SPACENOMICS</router-link></p>
          <p>to find out more about basic bonuses and extra reward for all M101 holders</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoadmapScheme',
  components: {},
  data() {
    return {
      items: [
        {
          title: 'SEASON 1',
          text: '',
          active: false,
          list: [
            'Launch',
            "Listing on <a href='https://rarity.tools/m101survivors' target='_blank'>Rarity tools</a>",
            "Making our own <a href='/ranking'>M101 Ranking </a>",
            'Start of merch concept design',
            'Drop of 13 NFT Sketches and 101 Starships for holders',
            "Sell out: Season 1 available now on <a href='https://opensea.io/collection/m101survivors' target='_blank'>OpenSea</a> only"
          ]
        },
        {
          title: 'SPACENOMICS',
          text: '',
          active: false,
          list: [
            'Activation of basic utility: ongoing ETH bonuses for Holders and Minters',
            "Introduction of <a href='/starpoints'>Star Points system</a>",
            'Boosting the holders’</br>reward pool by 15 ETH',
            'Adding ultimate reward for S1 holders: 50% of all proceeds from Season 2 into the reward pool'
          ]
        },
        {
          title: 'LIMITED COLLECTIONS',
          text: '',
          active: false,
          list: [
            "<a href='https://opensea.io/collection/m101starships' target='_blank'>M101 STARSHIPS</a>",
            "<a href='https://opensea.io/collection/101allstar' target='_blank'>M101 ALL-STAR</a>"
          ]
        },
        {
          title: 'SEASON 2',
          text: '<span class="name">Season 2</span><i>MINT IS LIVE!</i><p>It’s going to be legendary!</p>',
          active: false,
          list: [
            "Creation of special M101 <a href='https://allstar.m101.space/' target='_blank'>Comic Book Cover NFT </a>",
            'Start of community project for M101 Metaverse expansion: <a href="javascript:;">M101 Comic Book</a>'
          ]
        },
        {
          title: 'SANDBOX METAVERSE',
          text: '',
          active: false,
          list: [
            'Purchasing first <i>3 lands</i> in the Sandbox Game',
            '<i>Building Starship</i> from inside and out in M101 Metaverse',
            'Development of M101 Metaverse in Sandbox Game: integrating special functionality, bonuses and privileges for M101 holders',
            "Creation of limited collection of <i>Space Cabins</i> and it's integration in the Sandbox",
            '<i>Launch of M101 Sandbox Metaverse:</i> a special space, where holders will be able to enjoy the landscapes of the M101 Universe',
            'Introducing various quests, rewards and unique entertains'
          ]
        }
      ],
      arrowShow: false
    }
  },
  computed: {
    arrowStyle() {
      return this.arrowShow ? { opacity: 1 } : { opacity: 0 }
    }
  },
  mounted() {
    const int = setInterval(() => {
      this.arrowShow = !this.arrowShow
    }, 600)
    setTimeout(() => {
      clearInterval(int)
      this.arrowShow = false
    }, 3600)
  },
  methods: {
    toggleItems(i) {
      this.$toggleArray(i, this.items, 'active')
    }
  }
}
</script>
