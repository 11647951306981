<template>
  <div id="sptable" class="section sptable bg-dark">
    <div class="container">
      <h2 class="sptable__title">
        <span> MY STAR POINTS </span>
        <small>
          HOLDERS OF SEASON 1 AND 2 ARE GETTING 2 STAR POINTS PER 1 M101 NFT, WHICH THEY CAN USE IN
          THE
          <router-link to="/starshop">STAR SHOP</router-link>
        </small>
      </h2>
      <div v-if="currentWallet" class="sptable__text">Search by address</div>
      <div v-else class="sptable__text">
        Connect your MetaMask wallet to check your balance or search by address
      </div>
      <div class="sptable__search" :class="{ error: searchError }">
        <input
          v-model="searchAddress"
          type="text"
          placeholder="Search by address"
          @change="changeAddress()"
        />
        <button class="button button--search button--yellow" @click="getOwner()">
          <span></span><span></span><span></span><span></span><span></span><span></span>
          Find
          <svg>
            <use xlink:href="#zoom"></use>
          </svg>
        </button>
        <button
          v-if="currentWallet"
          class="button button--search button--yellow"
          @click="refresh()"
        >
          <span></span><span></span><span></span><span></span><span></span><span></span>
          Refresh
        </button>
      </div>
      <div class="sptable__owner">
        <div class="sptable__owner-item">
          <div class="sptable__owner-name">ETH ADDRESS</div>
          <div class="sptable__owner-values">
            <div class="sptable__owner-value w-xs-100">
              {{ computedOwner.address || currentWallet || 0 }}
            </div>
          </div>
        </div>
        <div class="sptable__owner-item">
          <div class="sptable__owner-name">M101 NFTs</div>
          <div class="sptable__owner-values">
            <div class="sptable__owner-value w-xs-100">{{ computedOwner.tokens_count || 0 }}</div>
          </div>
        </div>
        <div class="sptable__owner-item">
          <div class="sptable__owner-name">Star Points</div>
          <div class="sptable__owner-values">
            <div class="sptable__owner-value w-xs-100">{{ computedOwner.points_sum || 0 }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="sptable__claim">
        <div class="sptable__claim-text">
          You can either claim your reward once reaching 0.1 ETH or keep accumulating Star Points
          and its reward.
        </div>
        <button
          class="button button--search button--yellow"
          :class="{ 'button--transparent': claimError || !owner.address }"
          @click="disableClaim()"
        >
          <span></span><span></span><span></span><span></span><span></span><span></span>
          Claim
        </button>
      </div> -->
    </div>

    <!-- <div v-if="showClaimModal" class="modal">
      <div class="modal__overlay" @click.self="closeClaim()">
        <div class="container">
          <div class="modal__body bg-pink">
            <button class="button button--close-modal" @click="closeClaim()">&times;</button>
            <div class="modal__texts">
              <template v-if="claimError">
                <h3>{{ claimError }}</h3>
              </template>
              <template v-else-if="claimSuccess">
                <h3>Claim request has been sent</h3>
              </template>
              <template v-else>
                <h3>
                  Now all Star Points will be taken out of your balance and the following ETH amount
                  will be sent to your ETH address within 24 hours
                </h3>
                <div class="modal__btns">
                  <button class="button button--search button--yellow" @click="claim()">
                    <span></span><span></span><span></span><span></span><span></span><span></span>
                    Claim
                  </button>
                  <button class="button button--search button--yellow" @click="closeClaim()">
                    <span></span><span></span><span></span><span></span><span></span><span></span>
                    Cancel
                  </button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
import Web3 from 'web3'

export default {
  name: 'StarPointsTable',
  props: {},
  data() {
    return {
      totals: {},
      addressOwner: {},
      searchAddress: '',
      searchError: false,
      showClaimModal: false,
      claimSuccess: false
    }
  },
  computed: {
    owner() {
      return this.$root.currentOwner
    },
    currentWallet() {
      return this.$root.currentWallet
    },
    isWalletConnected() {
      return this.$root.isWalletConnected
    },
    computedOwner() {
      return this.addressOwner.address ? this.addressOwner : this.owner.address ? this.owner : {}
    },
    claimError() {
      return !this.owner.address ||
        (this.owner.balance_minter < 0.1 && this.owner.balance_points < 0.1)
        ? 'Sorry! You don`t have enough ETH on your balance to claim.'
        : ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.refresh()
    }, 200)
    this.getTotals()
  },
  methods: {
    async claim() {
      await this.$root.loginWeb3()

      const data = new FormData()
      data.append('id', this.owner.id)
      data.append('address', this.owner.address)

      const response = await axios
        .post(this.$root.apiUrl + '/claim', data, this.$root.headers)
        .catch(error => {
          console.log(error)
        })

      if (response.data.success) {
        this.claimSuccess = true

        setTimeout(() => {
          this.closeClaim()
          this.claimSuccess = false
        }, 3000)
      }
    },
    connectCheck() {
      if (!this.isWalletConnected) {
        this.$notify({
          group: 'app-notifications',
          type: 'error',
          text: 'Please connect your Metamask wallet.'
        })
        return false
      }

      return true
    },
    disableClaim() {
      this.$notify({
        duration: 6000,
        group: 'check-notifications',
        type: 'error',
        title: 'Attention!',
        text: '<p>Spacenomics is getting updated for Star Shop feature.</p>Please follow up Discord announcements.'
      })
    },
    showClaim() {
      if (!this.connectCheck()) {
        this.closeClaim()

        return
      }

      this.showClaimModal = true

      if (this.claimError) {
        setTimeout(() => {
          this.closeClaim()
        }, 3000)
      }
    },
    closeClaim() {
      this.showClaimModal = false
    },
    refresh() {
      this.addressOwner = {}
      this.searchAddress = this.currentWallet ? this.currentWallet : ''
    },
    changeAddress() {
      const address = this.searchAddress.replaceAll(' ', '').toLowerCase()

      if (!address.length) {
        this.refresh()
      }
    },
    async getOwner() {
      const address = this.searchAddress.replaceAll(' ', '').toLowerCase()

      if (!address.length) {
        return
      }

      if (!Web3.utils.isAddress(address)) {
        this.searchError = true
        setTimeout(() => {
          this.searchError = false
        }, 2000)
        return
      }

      const response = await fetch(this.$root.apiUrl + '/owner/' + address)

      if (response.ok) {
        this.addressOwner = await response.json()
      }
    },
    async getTotals() {
      const response = await fetch(this.$root.apiUrl + '/totals')

      if (response.ok) {
        this.totals = await response.json()
      }
    }
  }
}
</script>
