<template>
  <div v-if="presaleStart" id="mint" class="mint__content">
    <div class="minting__wrapper">
      <div v-if="soldOut" class="minting__panel">
        <span></span><span></span>
        <h3 class="minting__panel-sold">Sold out!</h3>
      </div>

      <div
        v-else
        class="minting__mint bg-dark"
        :class="$route.path === '/season-2' ? 'minting__mint--reward' : ''"
      >
        <span class="minting__mint-bg"></span>
        <span class="minting__mint-bg"></span>

        <h2 class="minting__title">{{ title }}</h2>
        <p class="minting__title minting__title--small minting__title--smallx">{{ subtitle }}</p>

        <div class="minting__title-sep"></div>

        <h2 class="minting__title minting__title--small">
          <span>MINTED </span>
          <p>{{ computedMintedCount }} / {{ computedTotalCount }}</p>
        </h2>

        <div class="minting__btnwrap">
          <plus-minus-input
            :current-value.sync="currentCount"
            :max-value="maxMintCount"
            :min-value="minMintCount"
          />

          <button class="button button--yellow" @click="handleMint">
            <span></span><span></span><span></span><span></span><span></span><span></span> MINT FOR
            {{ priceFormat }} <small>Ξ</small>
          </button>
        </div>
      </div>

      <div v-if="$route.path !== '/season-2'" class="minting__marquee">
        <dynamic-marquee
          :direction="'row'"
          :repeat-margin="0"
          :speed="{ type: 'duration', number: 20000 }"
          :hover-pause="false"
        >
          <span> {{ marqText }} </span>
        </dynamic-marquee>
      </div>

      <div v-if="$route.path !== '/season-2'" class="minting__image">
        <img :src="rocket" alt="rocket" />
      </div>

      <transaction :title.sync="modal.title" :text.sync="modal.text" :show.sync="modal.show" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Web3 from 'web3'
import { contractAddressS2, abiS2 } from '@/utils/shelter.js'
import PlusMinusInput from '@/components/mint/PlusMinusInput'
import Transaction from '@/components/mint/Transaction.vue'

const delimiter = 1e18
const unknown = 'xxxxx'
const staticTotal = 2101

const infura = 'https://mainnet.infura.io/v3/4a91eb50e36d430681e0000756d6261b'

const maxPresaleCount = 3
const maxSaleCount = 10

export default {
  name: 'Minting',
  components: { PlusMinusInput, Transaction },
  data() {
    return {
      rocket: require('@/assets/img/rocket.png'),
      web3: new Web3(Web3.givenProvider || infura),
      inWhiteList: false,
      currentCount: 0,
      shelterContract: null,
      mintedCount: null,
      totalCount: null,
      priceSale: null,
      pricePresale: null,
      salesStatus: 0,
      balance: 0,
      modal: {
        title: null,
        text: null,
        show: false
      },
      isMounted: false
    }
  },
  computed: {
    currentWallet() {
      return this.$root.currentWallet
    },
    isWalletConnected() {
      return this.$root.isWalletConnected
    },
    soldOut() {
      return this.$root.soldOut
    },
    presaleStart() {
      return this.$root.presaleStart
    },
    presaleEnd() {
      return this.$root.presaleEnd
    },
    saleStart() {
      return this.$root.saleStart
    },
    computedLeftCount() {
      return this.mintedCount !== null ? this.totalCount - this.mintedCount : 0
    },
    computedMintedCount() {
      return this.mintedCount !== null ? this.mintedCount : unknown
    },
    computedTotalCount() {
      return this.totalCount !== null ? staticTotal /* this.totalCount */ : unknown
    },
    maxMintCount() {
      let count = maxSaleCount

      if (!this.saleStart) {
        count = this.balance > 0 ? maxPresaleCount - this.balance : maxPresaleCount
        count = count < 0 ? 0 : count
      }

      return this.computedLeftCount < count ? this.computedLeftCount : count
    },
    minMintCount() {
      return this.computedLeftCount === 0 || this.maxMintCount === 0 ? 0 : 1
    },
    isPaused() {
      // не активен
      return this.salesStatus === 0
    },
    isSale() {
      // public sale
      return this.salesStatus === 1
    },
    // isPresale() {
    //   // whitelist sale
    //   return this.salesStatus === 2
    // },
    price() {
      return this.priceSale
    },
    priceFormat() {
      return (this.currentCount * (this.price / delimiter)).toFixed(2)
    },
    marqText() {
      return this.soldOut
        ? 'Sold out!'
        : this.saleStart
        ? 'PUBLIC SALE IS LIVE!'
        : 'PRE-SALE IS LIVE!'
    },
    title() {
      return this.saleStart ? 'M101 SHELTER PUBLIC SALE IS LIVE!' : 'SEASON 2 PRE-SALE IS LIVE!'
    },
    subtitle() {
      return this.saleStart
        ? 'ERC-721A OPTIMIZED SMART CONTRACT ALLOWS TO MINT MULTIPLE TOKENS AND PAY GAS FEE AS FOR MINTING ONE!'
        : 'MAX 3 NFTs PER ADDRESS'
    }
  },
  watch: {
    currentWallet(newV, oldV) {
      this.web3Handler()
    }
  },
  async mounted() {
    await this.web3Handler()

    this.isMounted = true
  },
  methods: {
    async checkNetwork() {
      const netId = await this.web3.eth.getChainId().catch(error => {
        console.log(error)
      })

      switch (netId) {
        case 1:
          // console.log('This is mainnet')
          break
        case 2:
          // console.log('This is the deprecated Morden test network.')
          break
        case 3:
          // console.log('This is the ropsten test network.')
          break
        default:
        // console.log('This is an unknown network.')
      }

      if (netId !== 1 && netId !== '1') {
        this.web3 = new Web3(infura)

        this.$notify({
          duration: 5000,
          group: 'app-notifications',
          type: 'error',
          text: 'You are on the wrong network. Please switch to Ethereum Mainnet!'
        })
      }

      return Promise.resolve()
    },
    async web3Handler() {
      await this.checkNetwork()

      this.shelterContract = new this.web3.eth.Contract(abiS2, contractAddressS2)

      const { shelterContract, currentWallet } = this

      const response = await Promise.all([
        shelterContract.methods.MAX_TOKENS().call(),
        shelterContract.methods.totalSupply().call(),
        shelterContract.methods.salesStatus().call(),
        shelterContract.methods.price().call(),
        shelterContract.methods.priceWL().call(),
        currentWallet !== null ? shelterContract.methods.balanceOf(currentWallet).call() : 0,
        currentWallet !== null
          ? axios.get(this.$root.apiUrl + '/whitelist/address/' + currentWallet).catch(error => {
              console.log(error)
              return false
            })
          : false
      ]).catch(error => {
        console.log(error)
        setTimeout(() => {
          this.web3Handler()
        }, 1000)
      })

      const [totalCount, mintedCount, salesStatus, price, pricePresale, balance, inWhiteList] =
        response

      this.totalCount = Number(totalCount)
      this.mintedCount = Number(mintedCount)
      this.salesStatus = Number(salesStatus)
      this.priceSale = Number(price)
      this.pricePresale = Number(pricePresale)
      this.balance = Number(balance)
      this.inWhiteList = inWhiteList ? Boolean(inWhiteList.data) : inWhiteList

      this.setCurrentCount()
      this.setSoldOut()

      return Promise.resolve()
    },
    setCurrentCount() {
      if (this.currentCount === 0) {
        this.currentCount = this.maxMintCount >= 1 ? 1 : this.maxMintCount
      }
    },
    setSoldOut() {
      if (this.mintedCount >= this.totalCount) {
        this.$root.soldOut = true
      }
    },
    async isReadyToMint() {
      if (!this.isWalletConnected) {
        this.$root.connectMetaMask()
        return Promise.resolve(false)
      }

      if (!this.currentWallet) {
        this.$notify({
          duration: 5000,
          group: 'app-notifications',
          type: 'error',
          text: 'MetaMask is not connected'
        })
        return Promise.resolve(false)
      }

      if (!this.saleStart) {
        if (!this.inWhiteList) {
          this.$notify({
            duration: 5000,
            group: 'app-notifications',
            type: 'error',
            text: 'Sorry! You`re not on whitelist!<br>If you want to get your spot and mint for 0.02 eth pleased click <a href="/whitelist" target="_blank">here</a> or wait the public sale.'
          })
          return Promise.resolve(false)
        }

        if (this.balance >= maxPresaleCount) {
          this.$notify({
            duration: 5000,
            group: 'app-notifications',
            type: 'error',
            text: 'You`ve already minted 3 NFTs! Wait public sale to mint more tokens.'
          })
          return Promise.resolve(false)
        }
      }

      if (this.currentCount > maxSaleCount) {
        this.$notify({
          duration: 5000,
          group: 'app-notifications',
          type: 'error',
          text: 'Max tokens are 10'
        })
        return Promise.resolve(false)
      }

      if (this.currentCount > this.computedLeftCount) {
        this.$notify({
          duration: 5000,
          group: 'app-notifications',
          type: 'error',
          text: 'Not enought tokens'
        })
        return Promise.resolve(false)
      }

      if (this.computedLeftCount <= 0) {
        this.$notify({
          duration: 5000,
          group: 'app-notifications',
          type: 'error',
          text: 'No shelteres available'
        })
        return Promise.resolve(false)
      }

      if (this.isPaused) {
        this.$notify({
          duration: 5000,
          group: 'app-notifications',
          type: 'error',
          text: 'Smart contract paused'
        })
        return Promise.resolve(false)
      }

      return Promise.resolve(true)
    },
    async handleMint() {
      this.FBLead()

      await this.web3Handler()

      const ready = await this.isReadyToMint()

      if (ready) {
        this.mint()
      }
    },
    mint() {
      const {
        shelterContract,
        currentWallet,
        price,
        currentCount,
        web3Handler,
        FBPurchase,
        showModal,
        closeModal
      } = this

      try {
        window.gtag('event', 'mint_start', {
          event_category: 'init',
          event_label: currentWallet,
          value: currentCount
        })
        console.log('mint_start')
      } catch (error) {
        console.log(error)
      }

      shelterContract.methods
        .mint(currentWallet, currentCount)
        .send({
          from: currentWallet,
          value: currentCount * price
        })
        .on('sending', function (payload) {})
        .on('sent', function (payload) {
          // showModal(`Transaction confirmation is pending`)
        })
        .on('transactionHash', hash => {
          try {
            window.gtag('event', 'mint_success', {
              event_category: 'transaction_hash',
              event_label: currentWallet,
              value: currentCount
            })
            console.log('mint_success')
          } catch (error) {
            console.log(error)
          }
          FBPurchase()
          showModal(
            `Congrats!<br>You've successfully minted M101 NFTs!`,
            `Your TX:<a href="https://etherscan.io/tx/${hash}" target="_blank">${hash}</a><small>Check out your tokens on <a href="https://opensea.io/account/m102expansion" target="_blank">OpenSea</a> now</small>`
          )
          web3Handler()
        })
        .on('confirmation', confirmationNumber => {})
        .on('error', error => {
          if (error.code !== 4001) {
            this.$notify({
              duration: 5000,
              group: 'app-notifications',
              type: 'error',
              text: String(error).includes('eth_sendTransaction')
                ? 'Please connect to Ethereum Mainnet'
                : error
            })
          }
          closeModal()
          web3Handler()
        })
    },
    showModal(title = '', text = '') {
      this.modal.title = title
      this.modal.text = text
      this.modal.show = true
    },
    closeModal() {
      this.modal.show = false
      this.modal.title = null
      this.modal.text = null
    },
    FBLead() {
      try {
        /* eslint-disable-next-line */
        FbEvents.Lead(
          [
            {
              id: this.mintedCount,
              quantity: this.currentCount,
              item_price: (this.price / delimiter).toFixed(2)
            }
          ],
          this.currentWallet
        )
      } catch (error) {
        console.log(error)
      }
    },
    FBPurchase() {
      try {
        /* eslint-disable-next-line */
        FbEvents.Purchase(
          [
            {
              id: this.mintedCount,
              quantity: this.currentCount,
              item_price: (this.price / delimiter).toFixed(2)
            }
          ],
          this.currentWallet
        )
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
