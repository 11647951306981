<template>
  <div v-if="status" :class="'congrats congrats--' + status">
    <span class="congrats-border-top"></span>
    <span class="congrats-border-bottom"></span>
    <span class="congrats-border-left"></span>
    <span class="congrats-border-right"></span>
    <template v-if="status === 'success'">
      <h2>CONGRATS!</h2>
      <p>YOU`RE ON THE M101 SHELTER SEASON 2 WHITELIST!</p>
    </template>
    <template v-else-if="status === 'pending'">
      <h2>THANK YOU!</h2>
      <p>YOUR REQUEST WAS SUCCESSFULLY SENT AND WILL BE PROCESSED WITHIN 24 HRS!</p>
    </template>
    <template v-else-if="['failed', 'banned'].includes(status)">
      <h2>SORRY!</h2>
      <p>SOMETHING WENT WRONG. PLEASE TRY AGAIN</p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Frame',
  props: {
    status: {
      type: String,
      default: ''
    }
  }
}
</script>
