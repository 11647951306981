<template>
  <main class="bg-main">
    <Header :sections="sections" :current-section="currentSection" />
    <Ranking />
    <Footer :sections-length="sections.length" :current-section="currentSection" />
  </main>
</template>

<script>
import Header from '@/components/common/Header'
import Footer from '@/components/common/Footer'
import Ranking from '@/components/common/Ranking'

export default {
  name: 'PointsPage',
  components: { Header, Footer, Ranking },
  props: {
    sections: {
      type: Array,
      required: true
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  data() {
    return {}
  },
  computed: {
    currentSection() {
      return this.sections[8]
    }
  },
  methods: {}
}
</script>
