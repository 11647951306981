<template>
  <div v-if="!saleStart" :class="'minting__countdown-wrapper ' + classStr">
    <span></span><span></span><span></span><span></span><span></span><span></span>
    <div class="minting__texts">
      <div class="minting__title">
        <p>
          <em v-html="startsText"></em>
          <br />
          <b class="minting__numbers">
            {{ computedCountDown.hours }}:{{ computedCountDown.minutes }}:{{
              computedCountDown.seconds
            }}
          </b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Counter',
  components: {},
  props: {
    classStr: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    presaleStart() {
      return this.$root.presaleStart
    },
    saleStart() {
      return this.$root.saleStart
    },
    computedCountDown() {
      const { hours, minutes, seconds } = this.$root.countDown
      return {
        hours: this.formatNumber(hours),
        minutes: this.formatNumber(minutes),
        seconds: this.formatNumber(seconds)
      }
    },
    startsText() {
      return this.presaleStart ? 'Public sale starts in' : 'Pre-sale starts in'
    }
  },
  created() {},
  mounted() {},
  methods: {
    formatNumber(number) {
      return number < 10 ? `0${number}` : number
    }
  }
}
</script>
