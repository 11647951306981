<template>
  <form class="footer__subscribe" action="#" @submit.prevent="submit()">
    <div class="footer__subscribe-text">
      <span>SUBSCRIBE TO OUR NEWSLETTER</span>
    </div>
    <div class="footer__subscribe-input" :class="{ error: form.email.error }">
      <input
        v-model="form.email.value"
        :type="form.email.type"
        :placeholder="form.email.placeholder"
      />
    </div>
    <button
      class="button button--yellow button--subscribe"
      :class="{ overlay: inProcess }"
      @click.prevent="submit()"
    >
      <span></span><span></span><span></span><span></span><span></span><span></span>
      Subscribe
    </button>
  </form>
</template>

<script>
import axios from 'axios'

const requestType = 7

export default {
  name: 'FooterSubscribe',
  components: {},
  props: {},
  data() {
    return {
      form: {
        email: {
          value: '',
          placeholder: 'Enter your email',
          error: '',
          type: 'email'
        }
      },
      inProcess: false
    }
  },
  computed: {
    currentWallet() {
      return this.$root.currentWallet
    },
    isWalletConnected() {
      return this.$root.isWalletConnected
    }
  },
  methods: {
    connectCheck() {
      if (!this.isWalletConnected) {
        this.$notify({
          group: 'check-notifications',
          type: 'error',
          text: 'Please connect your Metamask wallet.'
        })
        return false
      }

      return true
    },
    async submit() {
      if (!this.connectCheck() || this.inProcess) {
        return false
      }
      if (!this.$validate(this.form)) {
        return false
      }

      await this.$root.loginWeb3()

      this.inProcess = true

      const data = new FormData()
      const content = '<b>Email:</b> ' + this.form.email.value + '<br>'

      data.append('address', this.currentWallet)
      data.append('type', requestType)
      data.append('content', content)

      const response = await axios
        .post(this.$root.apiUrl + '/whitelist/request', data, this.$root.headers)
        .catch(error => {
          console.log(error)
        })

      if (response.data.success) {
        this.$notify({
          group: 'check-notifications',
          type: 'success',
          text: 'Your request has been sent.'
        })
        try {
          /* eslint-disable-next-line */
          FbEvents.InitiateCheckout([
            {
              id: 7,
              quantity: 1,
              item_price: 0
            }
          ])
        } catch (error) {
          console.log(error)
        }
        setTimeout(() => {
          this.form.email.value = ''
        }, 3000)
      } else {
        this.$notify({
          group: 'check-notifications',
          type: 'success',
          text: 'Your request has already been sent.'
        })
      }

      this.inProcess = false
    }
  }
}
</script>
