<template>
  <div id="video" class="video">
    <template v-if="!$root.isMobile">
      <video ref="videoref" poster="@/assets/img/video.jpg" autoplay muted loop>
        <source src="@/assets/img/video.mp4" type="video/mp4" />
        <source src="@/assets/img/video.webm" type="video/webm" />
      </video>
      <Counter />
      <a href="#" class="video__toggle" @click.prevent="toggleMute()">
        <svg width="32" height="32" aria-hidden="true">
          <use :xlink:href="mutedIcon"></use>
        </svg>
      </a>

      <router-link class="button button--yellow button--season-two" to="/season-2">
        <span></span><span></span><span></span><span></span><span></span><span></span>
        WARP INTO SEASON 2!
      </router-link>
    </template>
    <div v-else class="video__mobile">
      <Counter />
      <router-link class="button button--whitelist button--pink" to="/whitelist">
        <span></span><span></span><span></span><span></span><span></span><span></span>
        Whitelist me
      </router-link>
      <router-link class="button button--yellow button--season-two" to="/season-2">
        <span></span><span></span><span></span><span></span><span></span><span></span>
        WARP INTO SEASON 2!
      </router-link>
    </div>
  </div>
</template>

<script>
import Counter from '@/components/mint/Counter'

export default {
  name: 'Video',
  components: { Counter },
  props: {},
  data() {
    return {
      muted: true
    }
  },
  computed: {
    mutedIcon() {
      return this.muted ? '#mute-off' : '#mute-on'
    }
  },
  mounted() {},
  methods: {
    toggleMute() {
      this.muted = !this.muted
      this.$refs.videoref.muted = this.muted
    }
  }
}
</script>
