<template>
  <section class="ship bg-dark">
    <template v-if="!$root.isMobile">
      <video
        ref="shipVideo"
        class="ship__video"
        poster="@/assets/img/walk-on-ship.jpg"
        autoplay
        loop
        muted
      >
        <source src="@/assets/img/walk-on-ship.mp4" type="video/mp4" />
        <source src="@/assets/img/walk-on-ship.webm" type="video/webm" />
      </video>
      <a
        v-if="$root.isSmallTablet"
        href="#"
        class="ship__video-toggle"
        @click.prevent="toggleMute()"
      >
        <svg width="32" height="32" aria-hidden="true">
          <use :xlink:href="mutedIcon"></use>
        </svg>
      </a>
      <div class="container">
        <div class="ship__inner">
          <p class="ship__top">
            The <span>Sandbox Game</span> opens the new world for M101 holders. First of all, we
            have built our huge transgalactic <span>Starship X11nz/2 </span>with so much space
            inside to explore! Allocate your private Cabin, join Faction activities, participate in
            quests to find coins and even visit the Commander’s Deck.
          </p>
          <div class="ship__bottom">
            <p class="ship__bottom-title">M101 Avatars?</p>
            <div class="ship__bottom-text">
              <a
                v-if="!$root.isSmallTablet"
                href="#"
                class="ship__video-toggle"
                @click.prevent="toggleMute()"
              >
                <svg width="32" height="32" aria-hidden="true">
                  <use :xlink:href="mutedIcon"></use>
                </svg>
              </a>

              <p>Say no more - Survivors are getting voxelled!</p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="container">
        <div class="ship__inner">
          <p class="ship__top">
            The <span>Sandbox Game</span> opens the new world for M101 holders. First of all, we
            have built our huge transgalactic <span>Starship X11nz/2 </span>with so much space
            inside to explore! Allocate your private Cabin, join Faction activities, participate in
            quests to find coins and even visit the Commander’s Deck.
          </p>
          <div class="ship__video">
            <img src="@/assets/img/walk-on-ship.jpg" alt="walk on ship" />
          </div>
          <div class="ship__bottom">
            <p class="ship__bottom-title">M101 Avatars?</p>
            <div class="ship__bottom-text">Say no more - Survivors are getting voxelled!</div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: 'Video',
  data() {
    return {
      muted: true
    }
  },
  computed: {
    mutedIcon() {
      return this.muted ? '#mute-off' : '#mute-on'
    }
  },
  methods: {
    toggleMute() {
      this.muted = !this.muted
      this.$refs.shipVideo.muted = this.muted
    }
  }
}
</script>

<style></style>
