<template>
  <main class="bg-main">
    <Header class="bg-dark" :sections="sections" :current-section="currentSection" />
    <SeasonTwo />
    <Ship />
    <Merch />
    <Limited />
    <Smart />
    <Reward />
    <Footer class="bg-dark" :sections-length="sections.length" :current-section="currentSection" />
  </main>
</template>

<script>
import Header from '@/components/common/Header'
import Footer from '@/components/common/Footer'
import SeasonTwo from '@/components/season-two/SeasonTwo'
import Ship from '@/components/season-two/Ship'
import Merch from '@/components/season-two/Merch'
import Limited from '@/components/season-two/Limited'
import Smart from '@/components/season-two/Smart'
import Reward from '@/components/season-two/Reward'
export default {
  name: 'SeasonTwoPage',
  components: { Header, Footer, SeasonTwo, Ship, Merch, Limited, Smart, Reward },
  props: {
    sections: {
      type: Array,
      required: true
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  data() {
    return {}
  },
  computed: {
    currentSection() {
      return this.sections[3]
    }
  },
  methods: {}
}
</script>
