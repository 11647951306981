<template>
  <div id="season-two" class="season-two bg-dark">
    <div class="season-two__info">
      <div class="container">
        <div class="season-two__panel">
          <span></span>
          <span></span>
          <div class="season-two__panel-text">
            Welcome to the new chapter of M101 Shelter adventures: Metaverse Starship, private
            Cabins, 3D collection and more!
          </div>
        </div>
        <div class="welcome">
          <div class="welcome__inner">
            <div class="welcome__img-wrapper">
              <swiper
                ref="innerSwiper"
                :key="swiperKey"
                class="swiper"
                :options="swiperOptions"
                @ready="handleSwiperReadied"
              >
                <swiper-slide v-for="(slide, i) in slides" :key="i" class="welcome__img">
                  <img :src="slide" alt="Welcome to Season 2" />
                </swiper-slide>
              </swiper>
            </div>

            <div class="welcome__texts">
              <p class="welcome__title">NEW SEASON - NEW SURVIVORS</p>

              <p class="welcome__text">
                M101 collection significantly expands: now its side-profile characters - Survivors -
                have over <span>600</span> hand-drawn attributes and more than
                <span>2.800</span> color schemes. <br />As our storyline develops, you will see more
                than a <span>dozen</span> named Survivors, <span>2</span> new and animated High
                races, unique <span>1/1 hand-drawn </span>attributes and some unexpected surprises!
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="season-two__welcome">M101 Metaverse</p>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

const slides = []
for (let i = 1; i < 8; i++) {
  slides.push(require(`@/assets/img/slide_season_${i}.png`))
}

export default {
  name: 'SeasonTwo',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 0,
        mousewheel: false,
        simulateTouch: true,
        centeredSlides: true,
        allowTouchMove: false,
        centeredSlidesBounds: true,
        autoHeight: true,
        speed: 600,
        effect: 'slide',
        loop: true,
        autoplay: {
          delay: 2000
        }
      },
      swiper: null,
      swiperKey: 0,
      slides: slides
    }
  },
  watch: {
    '$root.width'(newV, oldV) {
      this.swiperKey = newV
      this.swiper.update()
      this.$refs.innerSwiper.$swiper.update()
      setTimeout(() => {
        this.swiperKey = newV + 1
        this.swiper.update()
        this.$refs.innerSwiper.$swiper.update()
      }, 400)
    }
  },
  mounted() {},
  methods: {
    handleSwiperReadied(swiper) {
      this.swiper = swiper
    }
  }
}
</script>
