<template>
  <div id="main" class="section main bg-pink">
    <Counter v-if="presaleStart" class-str="minting__countdown-wrapper--presale" />

    <template v-if="$root.isMobile">
      <div v-if="!presaleStart" class="marquee">
        <dynamic-marquee
          class="marquee-main"
          :direction="'row'"
          :repeat-margin="0"
          :speed="{ type: 'duration', number: 20000 }"
          :hover-pause="false"
        >
          <span v-html="marqText"></span>
        </dynamic-marquee>
      </div>
      <div class="container">
        <Minting />

        <div class="main__banner">
          <div class="main__banner-texts">
            <template v-if="!presaleStart">
              <h2>SEASON 2 STARTS ON MARCH 13!</h2>
              <p>
                <router-link to="/season-2">CHECK OUT</router-link> THE FULL LIST OF BONUSES AND
                METAVERSE FEATURES ON M101 SEASON 2
              </p>
            </template>
            <template v-else>
              <h3>
                <router-link to="/season-2">CHECK OUT</router-link>
                THE FULL LIST OF BONUSES AND METAVERSE FEATURES ON M101 SEASON 2
              </h3>
            </template>
          </div>
        </div>

        <div v-if="!presaleStart" class="main__content">
          <div class="video-frame">
            <div class="video-img">
              <div class="video-img-wrap">
                <img src="@/assets/img/video_2.gif" alt="video" />
              </div>
            </div>
          </div>
          <div class="minting">
            <div class="minting__panel">
              <span></span><span></span>
              <h3 class="minting__panel-soldbig">M101 SEASON 1 HAS SOLD OUT</h3>
              <p>WANT TO CHECK OUT THE COLLECTION AND BECOME A HOLDER?</p>
              <h3 class="minting__panel-sold">
                BUY ON
                <a href="https://opensea.io/collection/m101survivors" target="_blank">OPENSEA</a>
              </h3>
            </div>
          </div>
          <div class="texts">
            <h3>M101 SHELTER</h3>
            <div v-html="texts"></div>
          </div>
          <img :src="rocket" class="main__rocket" alt="rocket" />
        </div>

        <div v-else class="welcome">
          <div class="welcome__inner">
            <div class="welcome__img-wrapper">
              <swiper
                ref="innerSwiper"
                :key="swiperKey"
                class="swiper"
                :options="swiperOptions"
                @ready="handleSwiperReadied"
              >
                <swiper-slide v-for="(slide, i) in slides" :key="i" class="welcome__img">
                  <img :src="slide" alt="Welcome to Season 2" />
                </swiper-slide>
              </swiper>
            </div>
            <div class="welcome__texts">
              <p class="welcome__title">M101 Shelter</p>
              <div class="welcome__text" v-html="texts"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="collections">
        <div class="container">
          <h2 class="collections__title">M101 LIMITED COLLECTIONS</h2>
          <div class="collections__inner">
            <Starships />
            <Allstars />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="container">
        <Minting />

        <div class="main__banner">
          <div class="main__banner-texts">
            <template v-if="!presaleStart">
              <h2>SEASON 2 STARTS ON MARCH 13!</h2>
              <p>
                <router-link to="/season-2">CHECK OUT</router-link> THE FULL LIST OF BONUSES AND
                METAVERSE FEATURES ON M101 SEASON 2
              </p>
            </template>
            <template v-else>
              <h3>
                <router-link to="/season-2">CHECK OUT</router-link>
                THE FULL LIST OF BONUSES AND METAVERSE FEATURES ON M101 SEASON 2
              </h3>
            </template>
          </div>
        </div>

        <div v-if="!presaleStart" class="main__content">
          <div class="video-frame">
            <div class="video-img">
              <div class="video-img-wrap">
                <img src="@/assets/img/video_2.gif" alt="video" />
              </div>
            </div>
          </div>
          <div class="minting">
            <div class="minting__panel">
              <span></span><span></span>
              <h3 class="minting__panel-soldbig">M101 SEASON 1 HAS SOLD OUT</h3>
              <p>WANT TO CHECK OUT THE COLLECTION AND BECOME A HOLDER?</p>
              <h3 class="minting__panel-sold">
                BUY ON
                <a href="https://opensea.io/collection/m101survivors" target="_blank">OPENSEA</a>
              </h3>
            </div>
            <img :src="rocket" class="main__rocket" alt="rocket" />
          </div>
          <div class="texts">
            <h3>M101 SHELTER</h3>
            <vue-typed-js
              ref="typed"
              :strings="[texts]"
              :type-speed="15"
              :show-cursor="false"
              :content-type="'html'"
            >
              <div
                class="typing"
                @mouseenter="$refs.typed.typedObj.stop()"
                @mouseleave="$refs.typed.typedObj.start()"
              ></div>
            </vue-typed-js>
          </div>
        </div>

        <div v-else class="welcome">
          <div class="welcome__inner">
            <div class="welcome__img-wrapper">
              <swiper
                ref="innerSwiper"
                :key="swiperKey"
                class="swiper"
                :options="swiperOptions"
                @ready="handleSwiperReadied"
              >
                <swiper-slide v-for="(slide, i) in slides" :key="i" class="welcome__img">
                  <img :src="slide" alt="Welcome to Season 2" />
                </swiper-slide>
              </swiper>
            </div>
            <div class="welcome__texts">
              <p class="welcome__title">M101 Shelter</p>
              <div class="welcome__text" v-html="texts"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!presaleStart" class="marquee">
        <dynamic-marquee
          class="marquee-main"
          :direction="'row'"
          :repeat-margin="0"
          :speed="{ type: 'duration', number: 20000 }"
          :hover-pause="false"
        >
          <span v-html="marqText"></span>
        </dynamic-marquee>
      </div>

      <div class="collections">
        <div class="container">
          <h2 class="collections__title">M101 LIMITED COLLECTIONS</h2>
          <div class="collections__inner">
            <Starships />
            <Allstars />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import Minting from '@/components/mint/Minting'
import Allstars from '@/components/home/Allstars'
import Starships from '@/components/home/Starships'
import Counter from '@/components/mint/Counter'

const slides = []
for (let i = 1; i < 8; i++) {
  slides.push(require(`@/assets/img/slide_season_${i}.png`))
}

export default {
  name: 'Main',
  components: { Minting, Allstars, Starships, Swiper, SwiperSlide, Counter },
  props: {},
  data() {
    return {
      texts: `<p>The <b>M101 Shelter</b> is a NFT collection of generative side-profile characters with over 400 hand-drawn attributes and more than 2100 color schemes, which are ready to warp through Galaxy!</p><p><b>M101 Metaverse</b> includes: a unique Story that keeps unfolding, several Limited NFT collections, and Sandbox-based M101 space development.</p><p>Check out <b><a href="/roadmap">Roadmap</a></b> to find out about long-term plans and <b><a href="/spacenomics">Spacenomics</a></b> for the list of  holder's bonuses.</p>`,
      marqText:
        'PRE-SALE: MARCH 13 (4 pm UTC)&emsp;|&emsp;PUBLIC SALE: MARCH 13 (10 pm UTC)&emsp;|&emsp;PRE-SALE: MARCH 13 (4 pm UTC)&emsp;|&emsp;PUBLIC SALE: MARCH 13 (10 pm UTC)',
      rocket: require('@/assets/img/rocket.png'),
      swiperOptions: {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 0,
        mousewheel: false,
        simulateTouch: true,
        centeredSlides: true,
        allowTouchMove: false,
        centeredSlidesBounds: true,
        autoHeight: true,
        speed: 600,
        effect: 'slide',
        loop: true,
        autoplay: {
          delay: 2000
        }
      },
      swiperKey: 0,
      slides: slides,
      swiper: null
    }
  },
  computed: {
    soldOut() {
      return this.$root.soldOut
    },
    presaleStart() {
      return this.$root.presaleStart
    },
    presaleEnd() {
      return this.$root.presaleEnd
    },
    saleStart() {
      return this.$root.saleStart
    }
  },
  watch: {
    '$root.width'(newV, oldV) {
      if (this.swiper === null) {
        return
      }
      this.swiperKey = newV
      this.swiper.update()
      this.$refs.innerSwiper.$swiper.update()
      setTimeout(() => {
        this.swiperKey = newV + 1
        this.swiper.update()
        this.$refs.innerSwiper.$swiper.update()
      }, 400)
    }
  },
  mounted() {},
  methods: {
    handleSwiperReadied(swiper) {
      this.swiper = swiper
      if (this.swiperKey === 0) {
        setTimeout(() => {
          this.swiperKey++
          this.swiper.update()
          this.$refs.innerSwiper.$swiper.update()
        }, 400)
      }
    }
  }
}
</script>
