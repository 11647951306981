<template>
  <div v-if="show" class="modal">
    <div class="modal__overlay" @click.self="closeModal()">
      <div class="container">
        <div class="modal__body">
          <button class="button button--close-modal" @click="closeModal">&times;</button>
          <div class="modal__content">
            <h3 v-if="title" class="modal__title" v-html="title"></h3>
            <div v-if="text" class="modal__text" v-html="text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Transaction',
  props: {
    text: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  created() {
    document.addEventListener('keyup', evt => {
      if (evt.keyCode === 27) {
        this.closeModal()
      }
    })
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false)
      this.$emit('update:text', null)
      this.$emit('update:title', null)
    }
  }
}
</script>
