<template>
  <div id="story" class="section story">
    <div class="container">
      <div class="story__content">
        <div ref="scrolled" class="story__texts">
          <h2 class="story__title">M101 Story</h2>
          <div class="story__info">
            <p>
              The startdate changed only on 12 points since the Fold appeared on the edge of the
              Universe. If any space probe passed the visible part of it, all signals disappeared at
              once. The same happened with the starships and its crew. Moreover, the Fold was moving
              and absorbed numerous planets on its way. The connection with the vanished galaxies
              was considered as permanently lost, and it was ordered to beware any interaction with
              the Fold and stop all research and rescue missions.
            </p>
            <p>
              But a small number of Survivors from different galaxies that were either cautious,
              simply lucky, or rich enough, escaped the Fold on small starships. Now they are
              looking for a new safe home for their families and a new ship with powerful warp
              drive. Many settled in M101 Galaxy, that still lays far away from the Fold trajectory,
              and formed so-called ‘Shelters`: old patrol spaceships that remained after the Clash
              between the Xols and the Alliance.
            </p>
            <p>
              And now the last Survivors of M101 Shelter on the board of the starship with registry
              number X11nz/2 are about to face what`s coming their way.
            </p>
          </div>
          <!-- <a class="story__button button button--yellow" href="javascript:;">
            <span></span><span></span><span></span><span></span><span></span><span></span> Read the
            full story in our Discord
            <svg>
              <use xlink:href="#discord-square"></use>
            </svg>
          </a> -->
        </div>
        <div class="story__pic">
          <span class="story__pic-bg"></span>
          <span class="story__pic-bg"></span>
          <img src="@/assets/img/spaceship.png" alt="spaceship" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Story',
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
